import React from 'react'
import { Button, Header, Icon, Modal } from 'semantic-ui-react'

function ConfirmModal(props) {
  let {open, setOpen, onConfirm, header, content} = props
  return (
    <Modal
      closeIcon
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    >
      <Header icon='warning' content={header} />
      <Modal.Content>
        <p>{content}</p>
      </Modal.Content>
      <Modal.Actions>
        <Button color='green' onClick={() => {
          setOpen(false)
          onConfirm(true);
        }}>
          <Icon name='checkmark' /> Yes
        </Button>
        <Button color='red' onClick={() =>{
           setOpen(false);
           onConfirm(false);
        }}>
          <Icon name='remove' /> No
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default ConfirmModal