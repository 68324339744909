import axios from 'axios';
import {serverUrl} from './config';

class UserService {
    getUser = (currentUser, uid) => {
        return new Promise((resolve, reject) => {
            axios.get(`${serverUrl}/getUser/${uid}`, {
                headers:{
                    userToken: currentUser.uid
                }
            })
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            });
        })
    }
    deleteUser = (currentUser, uid) => {
        return new Promise((resolve, reject) => {
            axios.delete(`${serverUrl}/user/${uid}`, {
                headers:{
                    userToken: currentUser.uid
                }
            })
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            });
        })
    }
    disableUser = (currentUser, uid) => {
        return new Promise((resolve, reject) => {
            axios.post(`${serverUrl}/user/disable/${uid}`, {
                headers:{
                    userToken: currentUser.uid
                }
            })
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            });
        })
    }
    enableUser = (currentUser, uid) => {
        return new Promise((resolve, reject) => {
            axios.post(`${serverUrl}/user/enable/${uid}`, {
                headers:{
                    userToken: currentUser.uid
                }
            })
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            });
        })
    }
}
var userService = new UserService();
export default userService