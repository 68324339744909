import * as actionTypes from '../actions/types';

const initialOrgEditModalState = {
  isOpenUserEditModal: false,
  selectedUser: {}
}
  
const UserEditModalReducer = (state = initialOrgEditModalState, action) => {
  switch (action.type) {
    case actionTypes.SET_EDIT_USER_MODAL:
      return {
        ...state,
        isOpenUserEditModal: action.payload.isOpenUserEditModal,
        selectedUser: action.payload.selectedUser
      };
    default:
      return state;
  }
};

export default UserEditModalReducer