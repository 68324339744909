import React, { Component } from 'react';
import { v4 as uuid } from 'uuid';
import { Segment, Button, Input } from 'semantic-ui-react';
import { serverUrl } from '../../service/config';

import firebase from '../../firebase';
import FileModal from './FileModal';
import ProgressBar from './ProgressBar';
import { Picker, emojiIndex } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';

class MessageForm extends Component {
  state = {
    message: '',
    channel: this.props.currentChannel,
    typingRef: firebase.database().ref('typing'),
    user: this.props.currentUser,
    loading: false,
    errors: [],
    modal: false,
    uploadState: '',
    uploadTask: null,
    perccentUploaded: 0,
    storageRef: firebase.storage().ref(),
    emojiPicker: false,
  };

  componentWillUnmount() {
    if (this.state.uploadTask !== null) {
      this.state.uploadTask.cancel();
      this.setState({ uploadTask: null });
    }
  }

  openModal = () => this.setState({ modal: true });
  closeModal = () => this.setState({ modal: false });

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleKeyDown = (e) => {
    if (e.keyCode === 13 || e.keyCode === 10) {
      this.sendMessage();
    }

    const { message, typingRef, channel, user } = this.state;

    if (message) {
      typingRef.child(channel.id).child(user.uid).set(user.displayName);
    } else {
      typingRef.child(channel.id).child(user.uid).remove();
    }
  };

  handleTogglePicker = () => {
    this.setState({ emojiPicker: !this.state.emojiPicker });
  };

  handleAddEmoji = (emoji) => {
    const oldMessage = this.state.message;
    const newMessage = this.colonToUnicode(` ${oldMessage} ${emoji.colons}`);
    this.setState({ message: newMessage, emojiPicker: false });
    setTimeout(() => this.messageInputRef.focus(), 0);
  };

  moveCursorToEnd(obj) {
    if (!obj.updating) {
      obj.updating = true;
      var oldValue = obj.value;
      obj.value = '';
      setTimeout(() => {
        obj.value = oldValue;
        obj.updating = false;
      }, 0.1);
    }
  }

  colonToUnicode = (message) => {
    return message.replace(/:[A-Za-z0-9_+-]+:/g, (x) => {
      x = x.replace(/:/g, '');
      let emoji = emojiIndex.emojis[x];
      if (typeof emoji !== 'undefined') {
        let unicode = emoji.native;
        if (typeof unicode !== 'undefined') {
          return unicode;
        }
      }
      x = ':' + x + ':';
      return x;
    });
  };

  createMessage = (
    fileUrl = null,
    file_extension = null,
    file_name = null,
    messageId = null,
    savedFileName = null
  ) => {
    const { getMessagesRef } = this.props;
    console.log('createMessage');
    const message = {
      id: messageId['path']['pieces_'][
        getMessagesRef().child(this.state.channel.id).push()['path']['pieces_']
          .length - 1
      ],
      timestamp: firebase.database.ServerValue.TIMESTAMP,
      user: {
        id: this.state.user.uid,
        name: this.state.user.displayName,
        // avatar: this.state.user.photoURL,
        avatar: `${serverUrl}/api/avatar/${this.state.user.info.samlId}`, // backend endpoint for this user
        // chatbackend.com/api/user/sumlId/avatar
      },
    };

    if (fileUrl !== null && file_extension !== null) {
      if (
        file_extension === 'pdf' ||
        file_extension === 'xls' ||
        file_extension === 'xlsx' ||
        file_extension === 'ppt' ||
        file_extension === 'pptx' ||
        file_extension === 'doc' ||
        file_extension === 'docx'
      ) {
        message['document'] = fileUrl;
        message['file_type'] = file_extension;
      }
      if (
        file_extension === 'jpg' ||
        file_extension === 'jpeg' ||
        file_extension === 'png'
      ) {
        message['image'] = fileUrl;
        message['file_type'] = file_extension;
      }
    } else {
      message['content'] = this.state.message;
    }

    if (file_name !== null) {
      message['file_name'] = file_name;
    }

    if (savedFileName !== null) {
      message['fileName'] = savedFileName;
    }

    return message;
  };

  sendMessage = () => {
    const { getMessagesRef } = this.props;
    const { message, channel, typingRef, user } = this.state;

    if (message) {
      this.setState({ loading: true });

      var pushData = getMessagesRef().child(channel.id).push();

      pushData
        .set(this.createMessage(null, null, null, pushData, null))
        .then(() => {
          this.setState({ loading: false, message: '', errors: [] });
          typingRef.child(channel.id).child(user.uid).remove();
        })
        .catch((err) => {
          console.error(err);
          this.setState({
            loading: false,
            errors: this.state.errors.concat(err),
          });
        });
    } else {
      this.setState({
        errors: this.state.errors.concat({ message: 'Add a message' }),
      });
    }
  };

  getPath = () => {
    if (this.props.isPrivateChannel) {
      return `chat/private/${this.state.channel.id}`;
    } else {
      return 'chat/public';
    }
  };

  uploadFile = (file, metadata) => {
    const file_extension =
      file.name.split('.')[file.name.split('.').length - 1];
    const file_name = file.name;
    const savedFileName = uuid();
    const filePath = `${this.getPath()}/${savedFileName}.${file_extension}`;
    this.setState(
      {
        uploadState: 'uploading',
        uploadTask: this.state.storageRef.child(filePath).put(file, metadata),
      },
      () => {
        this.state.uploadTask.on(
          'state_changed',
          (snap) => {
            const perccentUploaded = Math.round(
              (snap.bytesTransferred / snap.totalBytes) * 100
            );
            this.props.isProgressBarVisible(perccentUploaded);
            this.setState({ perccentUploaded });
          },
          (err) => {
            console.error('error', err);
            this.setState({
              errors: this.state.errors.concat(err),
              uploadState: 'error',
              uploadTask: null,
            });
          },
          () => {
            this.state.uploadTask.snapshot.ref
              .getDownloadURL()
              .then((downloadUrl) => {
                this.sendFileMessage(
                  downloadUrl,
                  file_extension,
                  file_name,
                  savedFileName
                );
              })
              .catch((err) => {
                console.error('error', err);
                this.setState({
                  errors: this.state.errors.concat(err),
                  uploadState: 'error',
                  uploadTask: null,
                });
              });
          }
        );
      }
    );
  };

  sendFileMessage = (fileurl, file_extension, file_name, savedFileName) => {
    const { getMessagesRef } = this.props;
    const { channel } = this.state;
    var pushData = getMessagesRef().child(channel.id).push();
    pushData
      .set(
        this.createMessage(
          fileurl,
          file_extension,
          file_name,
          pushData,
          savedFileName
        )
      )
      .then(() => {
        this.setState({ uploadState: '' });
      })
      .catch((err) => {
        console.error(err);
        this.setState({ errors: this.state.errors.concat(err) });
      });
  };

  render() {
    // prettier-ignore
    const { errors, message, loading, modal, uploadState, perccentUploaded, emojiPicker } = this.state;
    return (
      <Segment className="message__form">
        {emojiPicker && (
          <Picker
            set="apple"
            onSelect={this.handleAddEmoji}
            className="emojipicker"
            title="Pick your emoji"
            emoji="point_up"
          />
        )}
        <Input
          fluid
          name="message"
          autoComplete="off"
          value={message}
          ref={(node) => (this.messageInputRef = node)}
          onKeyDown={this.handleKeyDown}
          onChange={this.handleChange}
          onFocus={this.moveCursorToEnd(this)}
          style={{ marginBottom: '0.7em' }}
          label={
            <Button
              icon={emojiPicker ? 'close' : 'smile'}
              content={emojiPicker ? 'Close' : null}
              onClick={this.handleTogglePicker}
            />
          }
          labelPosition="left"
          placeholder="Write Your Message"
          className={
            errors.some((error) => error.message.includes('message'))
              ? 'error'
              : ''
          }
        />
        <Button.Group icon widths="2">
          <Button
            disabled={loading}
            color="orange"
            content="Send Message"
            labelPosition="left"
            icon="edit"
            onClick={this.sendMessage}
          />
          <Button
            color="teal"
            disabled={uploadState === 'uploading'}
            content="Upload Media"
            labelPosition="right"
            icon="cloud upload"
            onClick={this.openModal}
          />
        </Button.Group>
        <FileModal
          modal={modal}
          closeModal={this.closeModal}
          uploadFile={this.uploadFile}
        />
        <ProgressBar
          uploadState={uploadState}
          perccentUploaded={perccentUploaded}
        />
      </Segment>
    );
  }
}

export default MessageForm;
