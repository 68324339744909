import React, { Component } from 'react';
import axios from 'axios';
import { Card, Image, Grid } from 'semantic-ui-react';
import firebase from '../../firebase';
import { serverUrl } from '../../service/config';

class UserInfo extends Component {
  state = {
    // user: this.props.currentUser,
    selectedUser: {},
    userDBInfo: null,
    usersRef: firebase.database().ref('users'),
    organisationsRef: firebase.database().ref('organisations'),
    channelsRef: firebase.database().ref('channels'),
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!Object.is(nextProps.selectedUser, this.state.selectedUser)) {
      this.setState(
        {
          selectedUser: nextProps.selectedUser,
        },
        this.getUserInfo
      );
    }
  }

  getUserInfo = () => {
    const { usersRef, organisationsRef, channelsRef } = this.state;
    const selectedUser = this.state.selectedUser;

    // console.log("this.state.selectedUser", this.state.selectedUser);
    if (!selectedUser.key) {
      return;
    }
    usersRef.child(selectedUser.key).once('value', (snap) => {
      let userDBInfo = snap.val();
      if (userDBInfo) {
        organisationsRef
          .child(userDBInfo.organisation)
          .once('value', (orgSnap) => {
            userDBInfo.organisation = orgSnap.val();
            // api call for rest
            axios
              .get(`${serverUrl}/api/expertise/${userDBInfo.samlId}`)
              .then((response) => {
                response.data.custom_fields.map((item) => {
                  if (item.label === 'Areas of Expertise') {
                    userDBInfo.expertise = item.textValue;
                  }
                });
                this.setState({
                  userDBInfo,
                });
              });
          });
      }
    });
  };

  render() {
    const { userDBInfo } = this.state;
    return userDBInfo ? (
      <Card className="w-100">
        <div className="user__info">
          <Grid>
            <Grid.Row>
              <Grid.Column width={11}>
                <h3>{userDBInfo.name}</h3>
                <div>
                  <p>
                    Name: <b>{userDBInfo.name}</b>
                  </p>
                  <p className="mail">
                    Email: <b>{userDBInfo.email}</b>
                  </p>
                  <p>
                    organization:{' '}
                    <b>
                      {userDBInfo.organisation && userDBInfo.organisation.name}
                    </b>
                  </p>
                  <p>Areas Of expertise</p>
                  <ul>
                    {userDBInfo.expertise && userDBInfo.expertise.length > 0
                      ? userDBInfo.expertise.map((name, index) => (
                          <li key={index}>
                            <p>
                              <strong>{name}</strong>
                            </p>
                          </li>
                        ))
                      : null}
                  </ul>
                </div>
              </Grid.Column>
              <Grid.Column width={5}>
                <Image
                  src={
                    userDBInfo
                      ? serverUrl + '/api/avatar/' + userDBInfo.samlId
                      : ''
                  }
                  size="tiny"
                  floated="right"
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </Card>
    ) : (
      <></>
    );
  }
}

export default UserInfo;
