import axios from 'axios';
import { serverUrl } from './config';
// import { connect } from "react-redux";

class MailService {
  constructor(props) {}

  sendMail = (currentUser, obj) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${serverUrl}/sendmail`, obj, {
          headers: {
            userToken: currentUser.uid,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}
export default new MailService();
