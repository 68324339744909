import React, { Component } from 'react';
import {
  Icon,
  Modal,
  Form,
  Input,
  Button,
  Grid,
  Image,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { setCloseAreasOfExpertiseModal } from '../../actions/index';
import AvatarEditor from 'react-avatar-editor';
import firebase from '../../firebase';

class EditAreasOfExpertiseModal extends Component {
  state = {
    id: '',
    user: this.props.currentUser,
    active: false,
    areasOfExpertiseRef: firebase.database().ref('channels'),
    croppedImage: '',
    uploadedCroppedImage: '',
    previewImage: '',
    modal: false,
    name: '',
    details: '',
    storageRef: firebase.storage().ref(),
    blob: '',
    state: false,
    logo: '',
  };

  componentDidMount() {
    this.setState({
      id: this.props.selectedAreaOfExpertise.id,
      name: this.props.selectedAreaOfExpertise.name,
      details: this.props.selectedAreaOfExpertise.details,
      state: this.props.selectedAreaOfExpertise.state,
      logo: this.props.selectedAreaOfExpertise.avatar,
    });
  }
  handleSubmit = (event) => {
    event.preventDefault();
    if (this.isFormValid(this.state)) {
      this.addAreaOfExpertise();
      this.closeModal();
    }
  };
  addAreaOfExpertise = () => {
    const {
      areasOfExpertiseRef,
      name,
      details,
      state,
      logo,
      user,
    } = this.state;
    const key = this.state.id;
    const newArea = {
      id: key,
      name: name,
      details: details,
      state: state,
      logo: logo === undefined ? '' : logo,
      createdBy: {
        name: user.displayName,
        avatar: user.photoURL,
      },
    };

    areasOfExpertiseRef
      .child(key)
      .update(newArea)
      .then(() => {
        this.setState({ name: '', details: '' });
        this.uploadCroppedImage(key);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  closeModal = () => {
    this.props.setCloseAreasOfExpertiseModal();
  };

  handleChange = (event) => {
    if (event.target.name === 'logo') {
      const file = event.target.files[0];
      const reader = new FileReader();

      if (file && file.type.match('image.*')) {
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          this.setState({ previewImage: reader.result });
        };
      }
    } else if (event.target.name === 'state') {
      this.setState({
        state: !this.state.state,
      });
    } else {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
  };
  isFormValid = ({ name, details }) => name && details;

  uploadCroppedImage = (id) => {
    const { storageRef, blob, metadata } = this.state;
    if (blob || metadata) {
      storageRef
        .child(`channels/` + id)
        .put(blob, metadata)
        .then((snap) => {
          snap.ref.getDownloadURL().then((downloadUrl) => {
            this.setState({ uploadedCroppedImage: downloadUrl });
            this.saveLogoUrl(id, downloadUrl);
          });
        });
    }

    this.closeModal();
  };

  saveLogoUrl = (key, downloadUrl) => {
    const { areasOfExpertiseRef } = this.state;

    areasOfExpertiseRef
      .child(key)
      .update({
        logo: downloadUrl,
      })
      .then(() => {
        console.log('save image url success');
      })
      .catch((error) => {
        console.error(error);
      });
  };

  handleCropImage = () => {
    if (this.AvatarEditor) {
      this.AvatarEditor.getImageScaledToCanvas().toBlob((blob) => {
        let imageUrl = URL.createObjectURL(blob);
        this.setState({
          croppedImage: imageUrl,
          blob,
        });
      });
    }
  };

  render() {
    const {
      isOpenAreaOfExpertiseModal,
      setCloseAreasOfExpertiseModal,
    } = this.props;
    const {
      previewImage,
      croppedImage,
      name,
      details,
      state,
      logo,
    } = this.state;
    return (
      <Modal
        basic
        open={isOpenAreaOfExpertiseModal}
        onClose={setCloseAreasOfExpertiseModal}
      >
        <div
          style={{
            textAlign: '-webkit-center',
          }}
        >
          <Image src={logo} />
        </div>
        <Modal.Header>Edit Channel</Modal.Header>
        <Modal.Content>
          <Form onSubmit={this.handleSubmit}>
            <Form.Field>
              <Input
                fluid
                label="Title"
                name="name"
                onChange={this.handleChange}
                value={name}
                style={{ marginBottom: '1em' }}
              />
            </Form.Field>
            <Form.Field>
              <Input
                fluid
                label="Details"
                name="details"
                value={details}
                onChange={this.handleChange}
                style={{ marginBottom: '1em' }}
              />
              <Input
                fluid
                label="Logo"
                name="logo"
                onChange={this.handleChange}
                type="file"
              />
              <div style={{ textAlign: 'right', color: 'white' }}>
                <label style={{ color: 'white', paddingLeft: '10' }}>
                  {' '}
                  State{' '}
                </label>
                <Form.Radio
                  toggle
                  name="state"
                  checked={state}
                  onChange={(e) => {
                    e.target.name = 'state';
                    this.handleChange(e);
                  }}
                />
              </div>
              <Grid centered stackable columns={2}>
                <Grid.Row centered>
                  <Grid.Column className="ui center aligned grid">
                    {previewImage && (
                      <AvatarEditor
                        image={previewImage}
                        ref={(node) => (this.AvatarEditor = node)}
                        // width={120}
                        // height={120}
                        border={50}
                        scale={1.2}
                      />
                    )}
                  </Grid.Column>
                  <Grid.Column>
                    {croppedImage && (
                      <Image
                        style={{ margin: '3.5em auto' }}
                        width={100}
                        height={100}
                        src={croppedImage}
                      />
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" inverted onClick={this.handleSubmit}>
            <Icon name="save" /> Save
          </Button>
          <Button color="green" inverted onClick={this.handleCropImage}>
            <Icon name="checkmark" /> Preview Image
          </Button>
          <Button color="red" inverted onClick={setCloseAreasOfExpertiseModal}>
            <Icon name="remove" /> Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default connect(null, { setCloseAreasOfExpertiseModal })(
  EditAreasOfExpertiseModal
);
