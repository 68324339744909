import React from 'react';
import { connect } from 'react-redux';
import {
  Header,
  Table,
  Button,
  Icon,
  Image,
  Form,
  Input,
  Dropdown,
} from 'semantic-ui-react';
import firebase from '../../firebase';
import ConfirmModal from './../common/confirmModal';

import { setCloseModal, setShowModal } from './../../actions/index';
const tagOptions = [
  {
    key: 'All',
    text: 'All',
    value: 'all',
    label: { color: 'orange', empty: true, circular: true },
  },
  {
    key: 'Enabled',
    text: 'Enabled',
    value: 'enabled',
    label: { color: 'green', empty: true, circular: true },
  },
  {
    key: 'disabled',
    text: 'Disabled',
    value: 'disabled',
    label: { color: 'red', empty: true, circular: true },
  },
];
class TableExamplePadded extends React.Component {
  state = {};
  constructor(props) {
    super(props);

    this.state = {
      orgs: [],
      orgRef: firebase.database().ref('organisations'),
      storageRef: firebase.storage().ref(),
      database: firebase.database(),
      modalOpen: false,
      selectedOrgId: '',
      groupNameIndex: '',
      groupstateIndex: '',
    };
  }

  UNSAFE_componentWillMount() {
    this.getOrgs();
  }

  componentWillUnmount() {
    this.removelistener();
  }

  removelistener = () => {
    // this.state.orgRef.off('value');
    // this.state.orgRef.off('child_changed');
    // this.state.orgRef.off('child_removed');
  };

  displayOrgs = (orgs) => {
    let keys = Object.keys(orgs);
    return keys.map((key, index) => (
      <Table.Row key={index}>
        <Table.Cell>
          <Header as="h5" textAlign="center">
            {index + 1}
          </Header>
        </Table.Cell>
        <Table.Cell singleLine>{orgs[key].name}</Table.Cell>
        <Table.Cell textAlign={'center'}>
          <Icon
            name="circle"
            size={'large'}
            color={orgs[key].state ? 'green' : 'red'}
          />
        </Table.Cell>
        <Table.Cell textAlign={'center'} width={'1'}>
          <Image
            src={orgs[key].logo}
            spaced="right"
            className="org-logo"
            size={'mini'}
          />
        </Table.Cell>
        <Table.Cell width={'1'}>
          {orgs[key].createdBy && orgs[key].createdBy.name}
        </Table.Cell>
        <Table.Cell>{orgs[key].details}</Table.Cell>
        <Table.Cell width={'1'}>
          <Button.Group>
            <Button
              positive
              onClick={() => {
                this.editOrganization(orgs[key]);
              }}
            >
              Edit
            </Button>
            <Button.Or />
            <Button color="red" onClick={() => this.deleteOrg(orgs[key].id)}>
              Delete
            </Button>
          </Button.Group>
        </Table.Cell>
      </Table.Row>
    ));
  };

  setOpen = (value) => {
    this.setState({
      modalOpen: value,
    });
  };
  onConfirm = (isOk) => {
    if (isOk) {
      let { database } = this.state;
      let key = this.state.selectedOrgId;
      if (key) {
        database.ref('organisations/' + key).remove(); // need to check move DEV
      } else {
        console.error(key);
      }
    }
  };

  getOrgs = () => {
    let { orgRef } = this.state;
    orgRef.on('value', (snap) => {
      if (snap.val()) this.setOrgs(snap.val());
    });
  };

  setOrgs = (orgs) => {
    if (orgs === undefined) {
      return;
    }
    let tempOrgs = Object.keys(orgs).map((key) => {
      return {
        ...orgs[key],
      };
    });
    this.setState({
      orgs: tempOrgs,
    });
    this.addListener();
    this.deleteListener();
  };
  addListener = () => {
    let { orgRef } = this.state;
    orgRef.on('child_changed', (snap) => {
      this.addOrgToState(snap.val());
    });
  };
  deleteListener = () => {
    let { orgRef } = this.state;
    orgRef.on('child_removed', (snap) => {
      let deletedOrg = snap.val();
      this.removeOrgOfState(deletedOrg);
    });
  };

  addOrgToState = (newOrg) => {
    let orgs = this.state.orgs.slice().filter((org) => {
      return org.id !== newOrg.id;
    });
    orgs.push(newOrg);
    this.setState({
      orgs,
    });
  };

  removeOrgOfState = (deletdOrg) => {
    let orgs = this.state.orgs.slice().filter((org) => {
      return org.id !== deletdOrg.id;
    });
    this.setState({
      orgs,
    });
  };

  deleteOrg = (key) => {
    this.setState({
      selectedOrgId: key,
      modalOpen: true,
    });
  };

  editOrganization = (org) => {
    this.props.setShowModal(org);
  };

  onSearchHandleChange = (e, data) => {
    this.setState({
      [e.target.name]: data.value,
    });
  };

  render() {
    let { orgs, modalOpen, groupNameIndex, groupstateIndex } = this.state;
    let regexp = new RegExp(groupNameIndex, 'i');
    orgs = orgs.filter(
      (org) =>
        org.name.search(regexp) > -1 ||
        org.createdBy.name.search(regexp) > -1 ||
        org.details.search(regexp) > -1
    );
    switch (groupstateIndex) {
      case 'disabled':
        orgs = orgs.filter((org) => org.state === false);
        break;
      case 'enabled':
        orgs = orgs.filter((org) => org.state !== false);
        break;
      default:
        break;
    }

    return (
      <div style={{ minWidth: '440px !important' }}>
        <Header as="h2" icon textAlign="center">
          <Icon name="group" circular />
          <Header.Content>Organisation</Header.Content>
        </Header>
        {/* search */}
        <Form>
          <Form.Group className="float-right">
            <Input
              icon={{ name: 'search', circular: true, link: true }}
              placeholder="Search organisation name..."
              onChange={(e, d) => {
                e.target.name = 'groupNameIndex';
                this.onSearchHandleChange(e, d);
              }}
            />
            <Dropdown
              text="Filter State"
              icon="filter"
              floating
              labeled
              button
              className="icon"
            >
              <Dropdown.Menu>
                <Input icon="search" iconPosition="left" className="search" />
                <Dropdown.Divider />
                <Dropdown.Header icon="users" content="Organisation state" />
                <Dropdown.Menu scrolling>
                  {tagOptions.map((option) => (
                    <Dropdown.Item
                      key={option.value}
                      {...option}
                      onClick={(e, d) => {
                        e.target.name = 'groupstateIndex';
                        this.onSearchHandleChange(e, d);
                      }}
                    />
                  ))}
                </Dropdown.Menu>
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
        </Form>
        {/* search */}
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell singleLine>No</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell textAlign={'center'}>State</Table.HeaderCell>
              <Table.HeaderCell>Logo</Table.HeaderCell>
              <Table.HeaderCell>Created By</Table.HeaderCell>
              <Table.HeaderCell textAlign={'center'}>Detail</Table.HeaderCell>
              <Table.HeaderCell>Edit</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{orgs.length > 0 && this.displayOrgs(orgs)}</Table.Body>
        </Table>
        <ConfirmModal
          open={modalOpen}
          setOpen={this.setOpen}
          onConfirm={this.onConfirm}
          header={'Delete Organisation'}
          content={'Are you really want to delete this organisation?'}
        />
      </div>
    );
  }
}

const actions = {
  setCloseModal,
  setShowModal,
};
export default connect(null, actions)(TableExamplePadded);
