import React from 'react';
import { connect } from 'react-redux';
import {
  Header,
  Table,
  Button,
  Icon,
  Image,
  Form,
  Input,
  Dropdown,
} from 'semantic-ui-react';
import firebase from '../../firebase';
import { setEditUserModal } from '../../actions/index';
import UserService from './../../service/user.service.admin';
import ConfirmModal from '../common/confirmModal';
const tagOptions = [
  {
    key: 'All',
    text: 'All',
    value: 'all',
    label: { color: 'orange', empty: true, circular: true },
  },
  {
    key: 'Enabled',
    text: 'Enabled',
    value: 'enabled',
    label: { color: 'green', empty: true, circular: true },
  },
  {
    key: 'disabled',
    text: 'Disabled',
    value: 'disabled',
    label: { color: 'red', empty: true, circular: true },
  },
];
class UsersTable extends React.Component {
  state = {
    user: this.props.currentUser,
    users: [],
    orgs: [],
    channels: [],
    usersRef: firebase.database().ref('users'),
    orgsRef: firebase.database().ref('organisations'),
    storageRef: firebase.storage().ref(),
    modalOpen: false,
    confirmValue: false,
    selectedUser: null,
    userNameIndex: '',
    usersStateIndex: '',
  };

  UNSAFE_componentWillMount() {
    this.getData();
    this.getOrgs();
  }

  componentWillUnmount() {
    this.removeListeners();
  }

  removeListeners() {
    // this.state.usersRef.off('value');
    // this.state.orgsRef.off('value');
  }

  getData = () => {
    return this.state.usersRef.on('value', (snap) => {
      if (snap.val()) {
        return this.setDataToState(snap.val());
      }
    });
  };

  setDataToState = (obj) => {
    let users = Object.keys(obj).map((key) => {
      return {
        key,
        ...obj[key],
      };
    });
    this.setState({
      users,
    });
  };

  getOrgs = () => {
    this.state.orgsRef.on('value', (snap) => {
      if (snap.val()) {
        return this.setOrgsToState(snap.val());
      }
    });
  };

  setOrgsToState = (obj) => {
    let orgs = Object.keys(obj).map((key) => {
      return {
        key,
        ...obj[key],
      };
    });
    this.setState({
      orgs,
    });
  };

  deleteUser = (user) => {
    this.setOpen(true);
    this.setState({
      selectedUser: user,
    });
  };

  displayUsers = (users, orgs) => {
    let keys = Object.keys(users);

    return keys.map((key, index) => {
      let org = orgs.find((org) => org.id === users[key].organisation);
      let orgName = '';
      if (org) {
        orgName = org.name;
      } else {
        orgName = users[key].organisation;
      }
      let channelsKey;
      if (users[key].channels) {
        channelsKey = Object.keys(users[key].channels);
      }
      let channels = [];
      if (channelsKey) {
        channels = channelsKey.map((cKey) => {
          return users[key].channels[cKey];
        });
      }
      return (
        <Table.Row key={index}>
          <Table.Cell>
            <Header as="h5" textAlign="center">
              {index + 1}
            </Header>
          </Table.Cell>
          <Table.Cell singleLine>{users[key].name}</Table.Cell>
          <Table.Cell singleLine>{users[key].email}</Table.Cell>
          <Table.Cell singleLine>{orgName}</Table.Cell>
          <Table.Cell singleLine textAlign="center">
            {this.displayStatus(channels, users[key].key)}
          </Table.Cell>
          <Table.Cell width={'1'} textAlign={'center'}>
            {users[key].state !== false ? (
              <Button color="green" className="circle" icon></Button>
            ) : (
              <Button color="red" className="circle" icon></Button>
            )}
          </Table.Cell>
          <Table.Cell textAlign={'center'}>
            <Image
              src={users[key].avatar}
              spaced="right"
              className="org-logo"
              size={'mini'}
            />
          </Table.Cell>
          {/* <Table.Cell>
          {users[key].details}
        </Table.Cell> */}
          <Table.Cell width={'1'}>
            <Button.Group>
              <Button
                positive
                onClick={() => {
                  this.editUser(users[key]);
                }}
              >
                Edit
              </Button>
              <Button.Or />
              <Button color="red" onClick={() => this.deleteUser(users[key])}>
                Delete
              </Button>
            </Button.Group>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  displayStatus = (channels, key) => {
    return (
      <Button.Group key={key + '_button'} vertical>
        {channels &&
          channels.map((channel, index) => (
            <Button
              color={channel.disabled !== false ? 'grey' : 'green'}
              onClick={() =>
                this.setChannelState(key, channel.id, !channel.disabled)
              }
              key={index}
            >
              {channel.name}
            </Button>
          ))}
      </Button.Group>
    );
  };
  editUser = (user) => {
    console.log('edit', user);
    this.props.setEditUserModal(true, user);
  };

  setOpen = (value) => {
    this.setState({
      modalOpen: value,
    });
  };

  onConfirm = (value) => {
    if (value) {
      let user = this.state.selectedUser;
      if (user.key) {
        UserService.deleteUser(this.state.user, user.key)
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  };

  setChannelState = (userId, channelId, state) => {
    const { usersRef } = this.state;
    usersRef
      .child(`${userId}/channels/${channelId}/disabled`)
      .set(state)
      .then(() => {})
      .catch((err) => console.error(err));
  };
  onSearchHandleChange = (e, data) => {
    this.setState({
      [e.target.name]: data.value,
    });
  };

  render() {
    let { users, orgs, userNameIndex, usersStateIndex } = this.state;
    let regexp = new RegExp(userNameIndex, 'i');
    users = users.filter(
      (user) => user.name.search(regexp) > -1 || user.email.search(regexp) > -1
    );
    switch (usersStateIndex) {
      case 'disabled':
        users = users.filter((user) => user.state === false);
        break;
      case 'enabled':
        users = users.filter((user) => user.state !== false);
        break;
      default:
        break;
    }

    return (
      <>
        <Header as="h2" icon textAlign="center">
          <Icon name="users" circular />
          <Header.Content>Users</Header.Content>
        </Header>
        {/* search */}
        <Form>
          <Form.Group className="float-right">
            <Input
              icon={{ name: 'search', circular: true, link: true }}
              placeholder="Search by name..."
              onChange={(e, d) => {
                e.target.name = 'userNameIndex';
                this.onSearchHandleChange(e, d);
              }}
            />
            <Dropdown
              text="State of Users"
              icon="filter"
              floating
              labeled
              button
              className="icon"
            >
              <Dropdown.Menu>
                <Input icon="search" iconPosition="left" className="search" />
                <Dropdown.Divider />
                <Dropdown.Header icon="users" content="State of Users" />
                <Dropdown.Menu scrolling>
                  {tagOptions.map((option) => (
                    <Dropdown.Item
                      key={option.value}
                      {...option}
                      onClick={(e, d) => {
                        e.target.name = 'usersStateIndex';
                        this.onSearchHandleChange(e, d);
                      }}
                    />
                  ))}
                </Dropdown.Menu>
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
        </Form>
        {/* search */}

        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell singleLine>No</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>Organisation</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                Channels ( On: <Icon name="circle" color="green" />
                Off: <Icon name="circle outline" />)
              </Table.HeaderCell>
              <Table.HeaderCell textAlign={'center'}>
                State(Enable/Disable)
              </Table.HeaderCell>
              <Table.HeaderCell textAlign={'center'}>Avatar</Table.HeaderCell>
              {/* <Table.HeaderCell>Detail</Table.HeaderCell> */}
              <Table.HeaderCell>Edit</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {users.length > 0 && this.displayUsers(users, orgs)}
          </Table.Body>
        </Table>
        <ConfirmModal
          open={this.state.modalOpen}
          setOpen={this.setOpen}
          onConfirm={this.onConfirm}
          header={'Delete User'}
          content={'Are you really want to delete this user?'}
        />
      </>
    );
  }
}

const actions = {
  setEditUserModal,
};
export default connect(null, actions)(UsersTable);
