import React from 'react';
import {
  Segment,
  Accordion,
  Header,
  Icon,
  Image,
  List,
} from 'semantic-ui-react';

import firebase from '../../firebase';

class MetaPanel extends React.Component {
  state = {
    activeIndex: 0,
    privateChannel: this.props.isPrivateChannel,
    channel: this.props.currentChannel,
    members: [],
    usersRef: firebase.database().ref('users'),
  };

  setActiveIndex = (event, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;
    this.setState({ activeIndex: newIndex });
  };

  formatCount = (num) =>
    num > 1 || num === 0 ? `${num} posts` : `${num} post`;

  displayTopPosters = (posts) =>
    Object.entries(posts)
      .sort((a, b) => b[1] - a[1])
      .map(([key, val], i) => (
        <List.Item key={i}>
          <Image avatar src={val.avatar} />
          <List.Content>
            <List.Header as="a">{key}</List.Header>
            <List.Description>{this.formatCount(val.count)}</List.Description>
          </List.Content>
        </List.Item>
      ))
      .slice(0, 5);

  getMembers = (channel) => {
    const { usersRef } = this.state;
    const id = channel.id;

    let members = [];

    if (id) {
      // get member for org
      usersRef
        .orderByChild('organisation')
        .equalTo(id)
        .once('value', (snap) => {
          snap.forEach((userSnap) => {
            let member = userSnap.val();
            members.push(member);
          });
        });
      //  get member for areas of expertise
      usersRef.once('value', (usersSnap) => {
        usersSnap.forEach((userSnap) => {
          if (userSnap.hasChild(`channels/${id}`)) {
            let member = userSnap.val();
            members.push(member);
          }
        });
      });
      this.setState({
        members,
      });
    }
  };

  displayMembers = (members = []) => {
    return members
      .sort((a, b) => b.name - a.name)
      .map((member, i) => (
        <List.Item key={i}>
          <Image avatar src={member.avatar} />
          <List.Content>
            <List.Header as="a">{member.name}</List.Header>
            <List.Description>{member.email}</List.Description>
          </List.Content>
        </List.Item>
      ));
  };

  render() {
    const { activeIndex, privateChannel, channel } = this.state;
    let members = this.state.members;
    const { userPosts, currentUser } = this.props;

    if (privateChannel) return null;
    members = members.filter(
      (member) => member.key !== currentUser.uid // key equal id
    );

    return (
      <Segment loading={!channel}>
        <Header as="h3" attached="top">
          About # {channel && channel.name}
        </Header>
        <Accordion styled attached="true">
          <Accordion.Title
            active={activeIndex === 0}
            index={0}
            onClick={this.setActiveIndex}
          >
            <Icon name="dropdown" />
            <Icon name="info" />
            Channel Details
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 0}>
            {channel && channel.details}
          </Accordion.Content>

          <Accordion.Title
            active={activeIndex === 1}
            index={1}
            onClick={this.setActiveIndex}
          >
            <Icon name="dropdown" />
            <Icon name="user circle" />
            Top Posters
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 1}>
            <List>{userPosts && this.displayTopPosters(userPosts)}</List>
          </Accordion.Content>

          {/* members  start */}
          <Accordion.Title
            active={activeIndex === 2}
            index={2}
            onClick={(e, d) => {
              this.setActiveIndex(e, d);
              this.getMembers(channel);
            }}
          >
            <Icon name="dropdown" />
            <Icon name="users" />
            Members
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 2}>
            <List>
              {members.length > 0 ? (
                this.displayMembers(members)
              ) : (
                <List.Item key={'no member'}>No member</List.Item>
              )}
            </List>
          </Accordion.Content>

          {/* members  end */}

          <Accordion.Title
            active={activeIndex === 3}
            index={3}
            onClick={this.setActiveIndex}
          >
            <Icon name="dropdown" />
            <Icon name="pencil alternate" />
            Created By
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 3}>
            <Header as="h3">
              <Image circular src={channel && channel.createdBy.avatar} />
              {channel && channel.createdBy.name}
            </Header>
          </Accordion.Content>
        </Accordion>
      </Segment>
    );
  }
}

export default MetaPanel;
