import React, { Component } from 'react';
import { Menu, Icon, Label } from 'semantic-ui-react';
import { connect } from 'react-redux';
import {
  setCurrentChannel,
  setPrivateChannel,
  setSelectedUser,
} from '../../actions/';
import { filterUsers } from '../../functions';
import firebase from '../../firebase';

class DirectMessages extends Component {
  titleRef;
  state = {
    user: this.props.currentUser,
    users: [],
    messages: {},
    activeChannel: '',
    searchIndex: this.props.searchIndex,
    searchType: this.props.searchType,
    searchOnline: this.props.searchOnline,
    myFriends: {},
    messagesRef: firebase.database().ref('messages'),
    usersRef: firebase.database().ref('users'),
    connectedRef: firebase.database().ref('.info/connected'),
    presenceRef: firebase.database().ref('presence'),
    privateMessagesRef: firebase.database().ref('privateMessages'),
    orgsRef: firebase.database().ref('organisations'),
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!Object.is(nextProps.searchIndex, this.state.searchIndex)) {
      this.setState({
        searchIndex: nextProps.searchIndex,
      });
    }
    if (!Object.is(nextProps.searchType, this.state.searchType)) {
      this.setState({
        searchType: nextProps.searchType,
      });
    }
    if (!Object.is(nextProps.searchOnline, this.state.searchOnline)) {
      this.setState({
        searchOnline: nextProps.searchOnline,
      });
    }
  }

  componentDidMount() {
    if (this.state.user) {
      this.addListeners(this.state.user.uid);
    }
    this.ajustPosition();
  }

  componentDidUpdate() {
    this.ajustPosition();
  }

  ajustPosition() {
    let title = document.querySelector('.directMessage__title');
    let position = this.cumulativeOffset(title);
    let bodyHeight = document.body.offsetHeight;

    if (bodyHeight < position.top + 250) {
      document.getElementById('users_list').style.height = `250px`;
    } else {
      let height = bodyHeight - position.top;
      document.getElementById('users_list').style.height = `${height}px`;
    }
  }

  componentWillUnmount() {
    // this.removeListeners();
  }

  cumulativeOffset = (element) => {
    return {
      top: element.offsetTop,
      left: element.offsetLeft,
    };
  };

  removeListeners = () => {
    this.state.usersRef.off();
    this.state.presenceRef.off();
    this.state.connectedRef.off();
  };

  addListeners = (currentUserId) => {
    let loadedUsers = [];
    this.state.usersRef.on('child_added', (snap) => {
      if (currentUserId !== snap.key) {
        let user = snap.val();
        user['uid'] = snap.key;
        user['status'] = 'offline';
        if (user.organisation) {
          this.state.orgsRef
            .child(user.organisation)
            .once('value', (orgSnap) => {
              user.organisation = orgSnap.val();
            });
        }
        loadedUsers.push(user);
        this.setState({ users: loadedUsers });
      }
    });

    this.state.connectedRef.on('value', (snap) => {
      if (snap.val() === true) {
        const ref = this.state.presenceRef.child(currentUserId);
        ref.set(true);
        ref.onDisconnect().remove((err) => {
          if (err !== null) {
            console.error(err);
          }
        });
      }
    });

    this.state.presenceRef.on('child_added', (snap) => {
      if (currentUserId !== snap.key) {
        this.addStatusToUser(snap.key);
      }
    });

    this.state.presenceRef.on('child_removed', (snap) => {
      if (currentUserId !== snap.key) {
        this.addStatusToUser(snap.key, false);
      }
    });

    this.state.usersRef.on('child_added', (snap) => {
      // for new messages
      if (snap.key !== this.state.user.uid) {
        this.newMessageListenerForUsers(snap.key);
      }
    });
  };

  newMessageListenerForUsers = (parterId) => {
    const channelID = this.getChannelId(parterId);
    this.state.privateMessagesRef.child(channelID).on('value', (snap) => {
      let val = snap.val();
      let dbMessages = [];
      if (val) {
        dbMessages = Object.keys(val).map((key) => {
          return val[key];
        });
        dbMessages = dbMessages.filter(
          (message) => message.user.id !== this.state.user.uid
        ); // delete my messages
        let oldMessages = this.state.messages;
        if (!oldMessages[parterId]) {
          // init
          oldMessages[parterId] = {};
          oldMessages[parterId].totalCount = dbMessages.length;
          oldMessages[parterId].newMessagesCount = 0;
        } else {
          // new message
          oldMessages[parterId].newMessagesCount =
            dbMessages.length - oldMessages[parterId].totalCount;
        }
        let myFriends = Object.assign({}, this.state.myFriends);
        myFriends[parterId] = {
          contacted: true,
        };
        this.setState(
          {
            messages: oldMessages,
            myFriends: myFriends,
          },
          () => {
            if (this.state.activeChannel === parterId) {
              this.makeReadedMessage(parterId);
            }
          }
        );
      }
    });
  };
  makeReadedMessage = (key) => {
    let messages = this.state.messages;
    if (messages[key]) {
      messages[key].totalCount += messages[key].newMessagesCount;
      messages[key].newMessagesCount = 0;
      this.setState({
        messages,
      });
    }
  };

  getNewMessageCount = (user) => {
    let messageData = this.state.messages[user.uid];
    if (messageData && messageData.newMessagesCount > 0) {
      return messageData.newMessagesCount;
    }
  };

  changeChannel = (user) => {
    const channelId = this.getChannelId(user.uid);
    const channelData = {
      id: channelId,
      name: user.name,
    };
    this.props.setCurrentChannel(channelData);
    this.props.setPrivateChannel(true);
    this.props.setSelectedUser(user);
    this.setActiveChannel(user.uid);
  };

  getChannelId = (userId) => {
    const currentUserId = this.state.user.uid;
    return userId < currentUserId
      ? `${userId}/${currentUserId}`
      : `${currentUserId}/${userId}`;
  };

  addStatusToUser = (userId, connected = true) => {
    const updatedUsers = this.state.users.reduce((acc, user) => {
      if (user.uid === userId) {
        user['status'] = `${connected ? 'online' : 'offline'}`;
      }
      return acc.concat(user);
    }, []);
    this.setState({ users: updatedUsers });
  };

  isUserOnline = (user) => user.status === 'online';

  setActiveChannel = (userId) => {
    this.setState({ activeChannel: userId });
  };

  render() {
    const {
      users,
      activeChannel,
      searchIndex,
      myFriends,
      searchType,
      searchOnline,
    } = this.state;

    let filteredUsers = filterUsers(
      users,
      searchIndex,
      searchType,
      searchOnline
    );
    return (
      <Menu.Menu
        className="menu"
        // style={{ height: 'auto', overflowY: 'hidden' }} // height was 52%
      >
        <Menu.Item className="directMessage__title">
          <span>
            <Icon name="mail" /> DIRECT MESSAGES
          </span>{' '}
          ({Object.keys(myFriends).length})
        </Menu.Item>
        <div id="users_list" style={{ height: '250px', overflowY: 'auto' }}>
          {filteredUsers.map((user, index) => {
            if (
              myFriends[user.uid] === undefined ||
              !myFriends[user.uid].contacted
            ) {
              return null;
            }
            return (
              <Menu.Item
                key={user.uid + index}
                active={user.uid === activeChannel}
                onClick={() => {
                  this.changeChannel(user);
                  this.makeReadedMessage(user.uid);
                }}
                disabled={user.state === false}
              >
                <Icon
                  name="circle"
                  color={this.isUserOnline(user) ? 'green' : 'red'}
                />
                {this.getNewMessageCount(user) && (
                  <Label color="red">{this.getNewMessageCount(user)}</Label>
                )}
                @ {user.name}
              </Menu.Item>
            );
          })}
        </div>
      </Menu.Menu>
    );
  }
}

const mapStateToProps = (state) => ({
  searchIndex: state.searchUser.userSearchIndex,
  searchType: state.searchUser.userSearchType,
  searchOnline: state.searchUser.userSearchOnline,
});

const mapDispatchToProps = {
  setCurrentChannel,
  setPrivateChannel,
  setSelectedUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(DirectMessages);
