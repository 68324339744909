import React, { Component } from 'react';
import {
  Icon,
  Accordion,
  List,
  Image,
  Segment,
  Header,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import firebase from '../../firebase';
import { setCurrentChannel, setPrivateChannel, setUser } from '../../actions';

class AllOrganizations extends Component {
  state = {
    user: this.props.currentUser,
    channels: [],
    channelName: '',
    channelDetails: '',
    modal: false,
    firstLoad: true,
    activeChannel: '',
    channel: null,
    notifications: [],
    channelsRef: firebase.database().ref('organisations'),
    usersRef: firebase.database().ref('users'),
    messagesRef: firebase.database().ref('messages'),
    typingRef: firebase.database().ref('typing'),
    activeIndex: '',
  };

  componentDidMount() {
    this.addListeners();
  }

  componentWillUnmount() {
    this.removeListeners();
  }

  addListeners = () => {
    let loadedOrgs = [];
    this.state.channelsRef.on('child_added', (snap) => {
      let val = snap.val();
      loadedOrgs.push(val);
      this.setState({ channels: loadedOrgs });
    });
  };

  deleteUserChannel = (channelId) => {
    this.state.usersRef
      .child(`${this.state.user.uid}/channels/${channelId}`)
      .remove();
  };

  removeListeners = () => {
    this.state.channelsRef.off();
  };

  changeChannel = (channel) => {
    this.setActiveChannel(channel);
    this.props.setCurrentChannel(channel);
    this.props.setPrivateChannel(false);
    window.scrollTo(0, 0);
    this.setState({ channel });
  };

  setActiveChannel = (channel) => {
    this.setState({ activeChannel: channel.id });
  };

  displayChannels = (channels) =>
    channels.length > 0 &&
    channels.map((channel) => (
      <List.Item
        key={channel.id}
        onClick={() => this.changeChannel(channel)}
        disabled={channel.state === false}
        className="channels-panel-item"
      >
        {channel.logo && channel.logo.indexOf('http') > -1 ? (
          <Image avatar src={channel.logo} />
        ) : (
          <Icon name={'road'} />
        )}
        <List.Content>
          <List.Header>{channel.name}</List.Header>
          <List.Description>{channel.details}</List.Description>
        </List.Content>
      </List.Item>
    ));

  setActiveIndex = (event, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;
    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { channels, activeIndex } = this.state;
    // const { activeIndex, setActiveIndex } = this.props
    return (
      <Segment loading={!channels}>
        <Header as="h3" attached="top">
          Organisations
        </Header>
        <Accordion styled attached="true">
          <Accordion.Title
            active={activeIndex === 1}
            index={1}
            onClick={(event, titleProps) =>
              this.setActiveIndex(event, titleProps)
            }
          >
            <Icon name="dropdown" />
            <Icon circular color="green" name="road" />
            All Organisations
          </Accordion.Title>
          <Accordion.Content
            active={activeIndex === 1}
            className="channels-panel"
          >
            <List>{channels && this.displayChannels(channels)}</List>
          </Accordion.Content>
        </Accordion>
      </Segment>
    );
  }
}

export default connect(null, {
  setCurrentChannel,
  setPrivateChannel,
  setUser,
})(AllOrganizations);
