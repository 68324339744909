import React, { Component } from 'react';
import {
  Icon,
  Modal,
  Form,
  Input,
  Button,
  Grid,
  Image,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { setEditUserModal } from '../../actions/index';
import AvatarEditor from 'react-avatar-editor';
import firebase from '../../firebase';
import UserService from '../../service/user.service.admin';

class EditUserModal extends Component {
  state = {
    id: '',
    user: this.props.currentUser,
    active: false,
    usersRef: firebase.database().ref('users'),
    orgRef: firebase.database().ref('organisations'),
    orgs: [],
    croppedImage: '',
    uploadedCroppedImage: '',
    previewImage: '',
    modal: false,
    name: '',
    details: '',
    storageRef: firebase.storage().ref(),
    blob: '',
    state: false,
    avatar: '',
    organisation: '',
    orgName: '',
  };

  UNSAFE_componentWillMount() {
    let id = this.props.selectedUser.organisation;
    if (id) {
      this.getOrg(id).then((res) => {
        if (res.val()) {
          this.setState({
            orgName: res.val().name,
          });
        }
      });
    }
  }

  getOrg = (id) => {
    return this.state.orgRef.child(id).once('value', function (snap) {
      return snap.val();
    });
  };

  componentDidMount() {
    this.setState({
      id: this.props.selectedUser.key,
      name: this.props.selectedUser.name,
      details: this.props.selectedUser.details,
      state: this.props.selectedUser.state === true ? true : false,
      avatar: this.props.selectedUser.avatar,
      organisation: this.props.selectedUser.organisation,
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.isFormValid(this.state)) {
      this.saveUser();
      this.hideModal();
    }
  };

  saveUser = () => {
    const { usersRef, name, state } = this.state;
    const key = this.state.id;

    if (state) {
      // disable or enable user
      this.enableuser(key);
    } else {
      this.disableuser(key);
    }

    const newData = {
      name: name,
      state: state,
    };
    usersRef
      .child(key)
      .update(newData)
      .then(() => {
        this.setState({ name: '', details: '' });
        this.uploadCroppedImage(key);
        this.hideModal();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  disableuser = (uid) => {
    let { user } = this.state;
    UserService.disableUser(user, uid)
      .then((res) => {
        console.log('disableuser', res);
      })
      .catch((err) => {
        console.error('disableuser', err);
      });
  };

  enableuser = (uid) => {
    let { user } = this.state;
    UserService.enableUser(user, uid)
      .then((res) => {
        console.log('enableuser', res);
      })
      .catch((err) => {
        console.error('enableuser', err);
      });
  };

  handleChange = (event) => {
    if (event.target.name === 'logo') {
      const file = event.target.files[0];
      const reader = new FileReader();

      if (file && file.type.match('image.*')) {
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          this.setState({ previewImage: reader.result });
        };
      }
    } else if (event.target.name === 'state') {
      this.setState({
        state: this.state.state === true ? false : true,
      });
    } else {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
  };

  isFormValid = ({ name }) => name;

  uploadCroppedImage = (id) => {
    const { storageRef, blob, metadata } = this.state;

    if (blob || metadata) {
      storageRef
        .child(`users/` + id)
        .put(blob, metadata)
        .then((snap) => {
          snap.ref.getDownloadURL().then((downloadUrl) => {
            this.setState({ uploadedCroppedImage: downloadUrl });
            this.saveLogoUrl(id, downloadUrl);
          });
        });
    }

    this.hideModal();
  };

  saveLogoUrl = (key, downloadUrl) => {
    const { usersRef } = this.state;

    usersRef
      .child(key)
      .update({
        avatar: downloadUrl,
      })
      .then(() => {
        console.log('image url saved successfully');
      })
      .catch((error) => {
        console.error(error);
      });
  };

  handleCropImage = () => {
    if (this.AvatarEditor) {
      this.AvatarEditor.getImageScaledToCanvas().toBlob((blob) => {
        let imageUrl = URL.createObjectURL(blob);
        this.setState({
          croppedImage: imageUrl,
          blob,
        });
      });
    }
  };

  hideModal = () => {
    this.props.setEditUserModal(false);
  };

  render() {
    const { isOpenUserEditModal, selectedUser } = this.props;
    const { previewImage, croppedImage, name, state, orgName } = this.state;

    return (
      <Modal basic open={isOpenUserEditModal} onClose={this.hideModal}>
        <Modal.Header>Edit {selectedUser.name}</Modal.Header>
        <Modal.Content>
          <Form onSubmit={this.handleSubmit}>
            <Form.Field>
              <Input
                fluid
                label="Name of User"
                name="name"
                // onChange={this.handleChange}
                value={name}
                style={{ marginBottom: '1em' }}
              />
            </Form.Field>
            <Form.Field>
              <Input
                fluid
                label={'Organisation: ' + orgName}
                name="details"
                value={orgName}
                onChange={this.handleChange}
                style={{ marginBottom: '1em' }}
              />
              <div style={{ textAlign: 'right', color: 'white' }}>
                <label style={{ color: 'white', paddingLeft: '10' }}>
                  {' '}
                  State{' '}
                </label>
                <Form.Radio
                  toggle
                  name="state"
                  checked={state}
                  onChange={(e) => {
                    e.target.name = 'state';
                    this.handleChange(e);
                  }}
                />
              </div>
              <Grid centered stackable columns={2}>
                <Grid.Row centered>
                  <Grid.Column className="ui center aligned grid">
                    {previewImage && (
                      <AvatarEditor
                        image={previewImage}
                        ref={(node) => (this.AvatarEditor = node)}
                        // width={120}
                        // height={120}
                        border={50}
                        scale={1.2}
                      />
                    )}
                  </Grid.Column>
                  <Grid.Column>
                    {croppedImage && (
                      <Image
                        style={{ margin: '3.5em auto' }}
                        width={100}
                        height={100}
                        src={croppedImage}
                      />
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" inverted onClick={this.handleSubmit}>
            <Icon name="save" /> Save
          </Button>
          <Button color="red" inverted onClick={this.hideModal}>
            <Icon name="remove" /> Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default connect(null, { setEditUserModal })(EditUserModal);
