import React from 'react';
import { Menu } from 'semantic-ui-react';
import AdminUserPanel from './AdminUserPanel';
// admin
import Organization from '../Organization';
import AreasOfExpertise from '../AreasOfExpertise';
import CustomAreasOfExpertise from '../CustomAreasOfExpertise';
import GroupUserManagement from '../GroupUserManagement';
import Users from '../Users';
// import ColorPanel from '../../ColorPanel/ColorPanel';

class AdminSidePanel extends React.Component {

  render() {
    const { currentUser, primaryColor, activedSideItemName } = this.props;
    return (
      <>
        <Menu
          size="large"
          inverted
          fixed="left"
          vertical
          // style={{ background: primaryColor, fontSize: '1.2rem' }}
          style={{ fontSize: '1.2rem' }}
          className={"admin-sidebar"}
        >
          <AdminUserPanel currentUser={currentUser} primaryColor={primaryColor} />
          <Organization currentUser={currentUser} activedSideItemName={activedSideItemName}/>
          <AreasOfExpertise currentUser={currentUser}  activedSideItemName={activedSideItemName}/>
          <CustomAreasOfExpertise currentUser={currentUser}  activedSideItemName={activedSideItemName}/>
          <GroupUserManagement currentUser={currentUser}  activedSideItemName={activedSideItemName}/>
          <Users currentUser={currentUser} activedSideItemName={activedSideItemName} />
        </Menu>
      </>
    );
  }
}

export default AdminSidePanel;
