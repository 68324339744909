import React from 'react';
import { Grid, Message, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import './App.css';
// import './App.scss';

// import ColorPanel from './ColorPanel/ColorPanel';
import SidePanel from './SidePanel/SidePanel';
import Messages from './Messages/Messages';
import MetaPanel from './MetaPanel/MetaPanel';
import MessageNotification from './common/MessageNotification';
import AreasOfExpertise from './GroupPanel/AreasOfExpertise';
import AllOrganizations from './MetaPanel/AllOrganizations';
import AllUsers from './GroupPanel/AllUsers';
import Passport from '../passportOrganizations';
import UserInfo from './MetaPanel/UserInfo';
import firebase from './../firebase';
import { setCurrentChannel } from './../actions/index';

// prettier-ignore
class App extends React.Component{
  state = {
    showAdminPanels: false,
    hash: '',
    channelsRef: firebase.database().ref('channels')
  }
  componentDidMount() {
  }
  
  UNSAFE_componentWillReceiveProps(nextProps) {
    if(!Object.is(this.state.hash, nextProps.location.hash)) {
      this.setState({
        hash: nextProps.location.hash
      });
      var hash = nextProps.location.hash.replace('#', '')
      this.state.channelsRef
        .orderByChild('refUrl')
        .equalTo(hash)
        .once('child_added', (snap) => {
          let val = snap.val();
          if(val) {
            this.props.setCurrentChannel(val);
          }
        })
    }
  }

  setAdminPanelShow = (val) => {
    this.setState({
      showAdminPanels: val
    })
  }

  render() {
    // prettier-ignore
    let { currentUser, currentChannel, isPrivateChannel, userPosts, primaryColor, secondaryColor, selectedUser} = this.props;

    let width = document.body.clientWidth
    let margin = 0;
    if(width < 881) {
      margin = 340
    }
    if(currentUser.info && currentUser.info.organisationInfo && !currentUser.info.organisationInfo.state) {
      return (
        <>
          <Message
            error
            header='Your Organization has been disabled.'
            list={[
              'Please wait till you organization be activated',
              'Or Please contact the Administrator',
            ]}
          />
        </>
      )
    }
    
    return(
      <>
        <Grid columns="equal" className="app" style={{ background: secondaryColor }}>
          {/* <ColorPanel
            key={currentUser && currentUser.name}
            currentUser={currentUser}
          /> */}
          <Grid.Column tablet={5} computer={4} style={{maxWidth: '250px'}}>            
            <SidePanel 
              id={currentUser && currentUser.uid} 
              currentUser={currentUser} 
              primaryColor={primaryColor} 
            />
          </Grid.Column>

          <Grid.Column className='mid__col' mobile={16} tablet={11} computer={12} largeScreen={8} widescreen={9}>
            <Messages
              key={currentChannel && currentChannel.id}
              currentChannel={currentChannel}
              currentUser={currentUser}
              isPrivateChannel={isPrivateChannel}
              />
          </Grid.Column> 
          
          <Grid.Column tablet={8} largeScreen={5} widescreen={4} only='large screen'>
            {/* style={{marginLeft: margin, overflowY: 'auto', height: 'calc(100vh - 145px)'}} */}
            <MetaPanel
              key={currentChannel && currentChannel.name}
              isPrivateChannel={isPrivateChannel}
              currentChannel={currentChannel}
              currentUser={currentUser} 
              userPosts={userPosts}
            />
            <AllUsers
              currentUser={currentUser}
            />
            <UserInfo selectedUser={selectedUser} />
            {
              currentUser && Passport.isAdmin(currentUser) && <Button fluid onClick={()=> {this.setAdminPanelShow(! this.state.showAdminPanels)}}>Admin Areas</Button>
            }
            
            {
              this.state.showAdminPanels &&  <AreasOfExpertise
                  currentUser={currentUser}
                />
            }
            {
              this.state.showAdminPanels &&  <AllOrganizations
                  currentUser={currentUser}
                />
            }
          </Grid.Column>
          <MessageNotification 
              currentUser={currentUser}
          /> 
        </Grid>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  currentChannel: state.channel.currentChannel,
  isPrivateChannel: state.channel.isPrivateChannel,
  userPosts: state.channel.userPosts,
  primaryColor: state.colors.primaryColor,
  secondaryColor: state.colors.secondaryColor,
  selectedUser: state.selectedUser.user,
});

export default connect(mapStateToProps, {
  setCurrentChannel,
})(App);
