import { combineReducers } from 'redux';
import * as actionTypes from '../actions/types';
// import * as sideType from '../components/Admin/SidePanel/type';
import organizationEditModalReducer from './OrganizationEditModal';
import AreaOfExpertiseEditModalReducer from './AreaOfExpertiseEditModal';
import UserEditModalReducer from './UserEditModal';
import GroupUserEditModalReducer from './GroupUserEditModal';
import {FILTER_TYPE, FILTER_USER_ONLINE} from '../functions';

const initialUserState = {
  currentUser: null,
  isLoading: true,
};

const userReducer = (state = initialUserState, action) => {
  switch (action.type) {
    case actionTypes.SET_USER:
      return {
        currentUser: action.payload.currentUser,
        isLoading: false,
      };

    case actionTypes.CLEAR_USER:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

const initialChannelState = {
  currentChannel: null,
  isPrivateChannel: false,
  userPosts: null,
};

const channelReducer = (state = initialChannelState, action) => {
  switch (action.type) {
    case actionTypes.SET_CURRENT_CHANNEL:
      return {
        ...state,
        currentChannel: action.payload.currentChannel,
      };
    case actionTypes.SET_PRIVATE_CHANNEL:
      return {
        ...state,
        isPrivateChannel: action.payload.isPrivateChannel,
      };
    case actionTypes.SET_USER_POSTS:
      return {
        ...state,
        userPosts: action.payload.userPosts,
      };

    default:
      return state;
  }
};

const iniitialColorState = {
  primaryColor: '#4a7ba4', //4c3c4c
  secondaryColor: '#eee',
};

const colorsReducer = (state = iniitialColorState, action) => {
  switch (action.type) {
    case actionTypes.SET_COLORS:
      return {
        ...state,
        primaryColor: action.payload.primaryColor,
        secondaryColor: action.payload.secondaryColor,
      };
    default:
      return state;
  }
};

const initialDisplayState = {
  isMessagesPanelShow: true,
  isMetaPanelShow: true,
  isORGTableShow: false,
};

const displayReducer = (state = initialDisplayState, action) => {
  switch (action.type) {
    case actionTypes.SET_DISPLAY_STATE_OF_MESSAGES:
      return {
        ...state,
        isMessagesPanelShow: action.payload.state,
      };
    case actionTypes.SET_DISPLAY_STATE_OF_METAPANEL:
      return {
        ...state,
        isMetaPanelShow: action.payload.state,
      };
    case actionTypes.SET_DISPLAY_STATE_OF_ORGTABLE:
      return {
        ...state,
        isORGTableShow: action.payload.state,
      };
    default:
      return state;
  }
};

const initialAdminSideBarState = {
  activedSideItemName: '',
};

const adminSideBarReducer = (state = initialAdminSideBarState, action) => {
  switch (action.type) {
    case actionTypes.SET_ACTIVE_ITEM_OF_ADMIN_SIDEBAR:
      return {
        ...state,
        activedSideItemName: action.payload.sideItemName,
      };
    default:
      return state;
  }
};

const searchUserReducer = (state = { userSearchIndex: '', userSearchType: FILTER_TYPE.NAME, userSearchOnline: FILTER_USER_ONLINE.ALL}, action) => {
  switch (action.type) {
    case actionTypes.SET_SEARCH_USER:
      return {
        ...state,
        userSearchIndex: action.payload.userSearchIndex,
        userSearchType: action.payload.userSearchType,
        userSearchOnline: action.payload.userSearchOnline,
      };
    default:
      return state;
  }
};

const selectUserReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case actionTypes.SET_SELECTED_USER:
      return {
        ...state,
        user: action.payload.selectedUser,
      };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  user: userReducer,
  channel: channelReducer,
  colors: colorsReducer,
  display: displayReducer,
  adminSidebar: adminSideBarReducer,
  organizationEditModal: organizationEditModalReducer,
  AreaOfExpertiseEditModal: AreaOfExpertiseEditModalReducer,
  userEditModal: UserEditModalReducer,
  groupUserEditModal: GroupUserEditModalReducer,
  searchUser: searchUserReducer,
  selectedUser: selectUserReducer,
});

export default rootReducer;
