import React, { Component } from 'react';
import { Menu, Icon, Label } from 'semantic-ui-react';
import { connect } from 'react-redux';
import firebase from '../../firebase';
import { setCurrentChannel, setPrivateChannel, setUser } from '../../actions/';

class PublicChannels extends Component {
  state = {
    user: this.props.currentUser,
    channels: [],
    channelName: '',
    channelDetails: '',
    modal: false,
    firstLoad: true,
    activeChannel: this.props.currentChannel
      ? this.props.currentChannel.id
      : '',
    channel: null,
    notifications: [],
    messages: {},
    childHidden: false,
    channelsRef: firebase.database().ref('channels'),
    usersRef: firebase.database().ref('users'),
    messagesRef: firebase.database().ref('messages'),
    typingRef: firebase.database().ref('typing'),
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!Object.is(this.state.activeChannel, nextProps.currentChannel)) {
      this.setState({
        activeChannel: nextProps.currentChannel
          ? nextProps.currentChannel.id
          : '',
      });
    }
  }

  componentDidMount() {
    this.addListeners();
  }

  componentWillUnmount() {
    this.removeListeners();
  }

  addListeners = () => {
    let loadedChannels = [];
    this.state.channelsRef.on('child_added', (snap) => {
      let val = snap.val();
      if (val.public) {
        loadedChannels.push(val);
        this.setState({ channels: loadedChannels });
        this.getInitMessages(snap.key);
      }
    });
  };

  getInitMessages = (key) => {
    this.state.messagesRef.child(key).on('value', (snap) => {
      let dbMessages = [];
      if (snap.val()) {
        dbMessages = Object.keys(snap.val()).map((key) => snap.val()[key]);
      }
      dbMessages = dbMessages.filter(
        (message) => message.user.id !== this.state.user.uid
      );
      let messages = this.state.messages;
      if (!messages[key]) {
        messages[key] = {};
        messages[key].totalCount = dbMessages.length;
        messages[key].newMessageCount = 0;
      } else {
        messages[key].newMessageCount =
          dbMessages.length - messages[key].totalCount;
      }
      this.setState(
        {
          messages,
        },
        () => {
          if (this.state.activeChannel === key) {
            this.makeReadedMessage(key);
          }
        }
      );
    });
  };

  makeReadedMessage = (key) => {
    let messages = Object.assign({}, this.state.messages);
    if (messages[key]) {
      messages[key].totalCount += messages[key].newMessageCount;
      messages[key].newMessageCount = 0;
      this.setState({
        messages,
      });
    }
  };

  removeListeners = () => {
    this.state.channelsRef.off();
    this.state.channels.forEach((channel) => {
      this.state.messagesRef.child(channel.id).off();
    });
  };

  changeChannel = (channel) => {
    this.setActiveChannel(channel);
    if (this.state.channel) {
      this.state.typingRef
        .child(this.state.channel.id)
        .child(this.state.user.uid)
        .remove();
    }
    this.props.setCurrentChannel(channel);
    this.props.setPrivateChannel(false);
    this.setState({ channel });
  };

  setActiveChannel = (channel) => {
    this.setState({ activeChannel: channel.id });
  };

  getNewMessageCount = (channel) => {
    let chanelData = this.state.messages[channel.id];
    if (chanelData && chanelData.newMessageCount > 0) {
      return chanelData.newMessageCount;
    }
  };

  displayChannels = (channels) =>
    channels.length > 0 &&
    channels.map(
      (channel) =>
        channel.public === true && (
          <Menu.Item
            key={channel.id}
            onClick={() => {
              this.changeChannel(channel);
              this.makeReadedMessage(channel.id);
            }}
            name={channel.name}
            style={{ opacity: 0.7 }}
            active={channel.id === this.state.activeChannel}
            disabled={channel.state === false}
          >
            {this.getNewMessageCount(channel) && (
              <Label color="red">{this.getNewMessageCount(channel)}</Label>
            )}
            # {channel.name}
          </Menu.Item>
        )
    );

  render() {
    const { channels, childHidden } = this.state;
    return (
      <>
        <Menu.Menu className="menu">
          <Menu.Item
            className="pr-p5"
            onClick={() => {
              this.setState({
                childHidden: !this.state.childHidden,
              });
            }}
          >
            <span>
              <Icon name="exchange" /> PUBLIC CHANNELS
            </span>{' '}
            ({channels.length}){' '}
            {!childHidden ? (
              <Icon name="minus" style={{ cursor: 'pointer' }} />
            ) : (
              <Icon name="plus" style={{ cursor: 'pointer' }} />
            )}
          </Menu.Item>
          {!childHidden && this.displayChannels(channels)}
        </Menu.Menu>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentChannel: state.channel.currentChannel,
  };
};

const mapDispatchToProps = {
  setCurrentChannel,
  setPrivateChannel,
  setUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(PublicChannels);
