import React, { Component } from "react";
import {
    Icon,
    Modal,
    Form,
    Input,
    Button,
    Dropdown
} from 'semantic-ui-react';
import { connect } from 'react-redux'
import {
    setEditGroupUserModal
} from "../../actions/index"
// import AvatarEditor from 'react-avatar-editor';
import firebase from '../../firebase';
import UserService from '../../service/user.service.admin';

class GroupUserEditModal  extends Component {
    state = {
        user: this.props.currentUser,
        usersRef: firebase.database().ref('users'),
        orgRef: firebase.database().ref('organisations'),
        orgs: [],
        id: "",
        avatar: "",
        channels: {},
        email: "",
        name: "",
        organisation: "",
        organisationName: "",
        samlId: "",
        state: false,
        orgsOptions: []
    };

    UNSAFE_componentWillMount() {
        this.addListeners();
    }

    componentWillUnmount() {
        // this.state.orgRef.off("value");
    }

    addListeners() {
        this.state.orgRef.on("value", (snap) => {
            let val = snap.val();
            if(val) {
                let orgs = Object.keys(val).map((v) => 
                    val[v]
                )
                let orgsOptions = orgs.map((org) => {
                    return {
                        key: org.id,
                        text: org.name,
                        value: org.id
                    }
                });
                let organisationName = orgs.find((org) => org.id === this.props.selectedGroupUser.organisation).name
                this.setState({
                    orgs,
                    orgsOptions,
                    organisationName
                });
            }
        });
    }

    UNSAFE_componentWillMount() {
        this.setState({
            id: this.props.selectedGroupUser.key,
            avatar: this.props.selectedGroupUser.avatar,
            channels: this.props.selectedGroupUser.channels,
            email: this.props.selectedGroupUser.email,
            name: this.props.selectedGroupUser.name,
            organisation: this.props.selectedGroupUser.organisation,
            samlId: this.props.selectedGroupUser.samlId,
            state: this.props.selectedGroupUser.state,
        });
        console.log("this.props.selectedGroupUser", this.props.selectedGroupUser);
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (this.isFormValid(this.state)) {
          this.saveUser();
          this.hideModal();
        }
    };

    saveUser = () => {
        const { usersRef, organisation } = this.state;
        const key = this.state.id;

        const newData = {
            organisation: organisation
        };
        usersRef
          .child(key)
          .update(newData)
          .then(() => {
            this.setState({ 
                id: "",
                avatar: "",
                channels: {},
                email: "",
                name: "",
                organisation: "",
                samlId: "",
            });
          })
          .catch((err) => {
            console.error(err);
          });
    }

    disableuser = (uid) => {
        let { user } = this.state
        UserService
            .disableUser(user, uid)
            .then((res) => {
                console.log("disableuser", res);
            })
            .catch((err) => {
                console.error("disableuser", err);
            })
    }

    enableuser = (uid) => {
        let { user } = this.state
        UserService
            .enableUser(user, uid)
            .then((res) => {
                console.log("enableuser", res);
            })
            .catch((err) => {
                console.error("enableuser", err);
            })
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    isFormValid = ({ name }) => name;
    
    hideModal = () => {
        this.props.setEditGroupUserModal(false)
    }

    render() {
        const {isOpenGroupUserEditModal} = this.props;
        const { name, orgsOptions, organisation} = this.state;
        
        return (
            <Modal basic open={isOpenGroupUserEditModal} onClose={this.hideModal}>
                <Modal.Header>Edit Group User </Modal.Header>
                <Modal.Content>
                    <Form onSubmit={this.handleSubmit}>
                    <Form.Field >
                        <Input
                            fluid
                            label="Name of User"
                            name="name"
                            onChange={this.handleChange}
                            value={name}
                            style={{ marginBottom: '1em' }}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Dropdown 
                            placeholder='Groups' 
                            search 
                            selection
                            options={orgsOptions}  
                            onChange={(e,d) =>{
                                e.target.name = "organisation"
                                e.target.value = d.value
                                this.handleChange(e)
                            }}
                            value={organisation}
                        />
                        {/* <div style={{textAlign: "right", color: "white"}}>
                            <label style={{color: "white", paddingLeft: "10"}}> State </label>
                            <Form.Radio toggle name="state" checked={state} onChange={(e) => {
                                e.target.name = "state"
                                this.handleChange(e)
                            }}/>
                        </div> */}
                    </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button color="green" inverted 
                        onClick={this.handleSubmit}
                    >
                        <Icon name="save" /> Save
                    </Button>
                    <Button color="red" inverted onClick={this.hideModal}>
                        <Icon name="remove" /> Cancel
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    }
}


export default connect(null, {setEditGroupUserModal })(GroupUserEditModal)