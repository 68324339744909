import React, { Component } from 'react';
import {
  Grid,
  Header,
  Icon,
  Dropdown,
  Image,
  Modal,
  Input,
  Button,
  Checkbox,
  Form,
  Label,
} from 'semantic-ui-react';
import AvatarEditor from 'react-avatar-editor';
import { connect } from 'react-redux';
import firebase from '../../firebase';
import { Link } from 'react-router-dom';
import { isAdmin } from '../../passportOrganizations';
import { setUser } from '../../actions';

class UserPanel extends Component {
  state = {
    user: this.props.currentUser,
    modal: false,
    settingModal: false,
    previewImage: '',
    croppedImage: '',
    blob: '',
    metadata: {
      contentType: 'image/jpeg',
    },
    uploadedCroppedImage: '',
    storageRef: firebase.storage().ref(),
    userRef: firebase.auth().currentUser,
    usersRef: firebase.database().ref('users'),
    samlProvider: new firebase.auth.SAMLAuthProvider('saml.ff-demo-chat-local'),
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (Object.is(this.state.user, nextProps.user)) {
      this.setState({
        user: nextProps.user,
      });
    }
  }

  setNotification = () => {
    const { usersRef, user } = this.state;
    let disabledNotification = user.info.disabledNotification;
    usersRef
      .child(`${user.uid}/disabledNotification`)
      .set(!disabledNotification)
      .then(() => {
        user.info.disabledNotification = !disabledNotification;
        this.setState(
          {
            user: Object.assign({}, user),
          },
          () => this.props.setUser(Object.assign({}, user))
        );
      });
  };
  setSoundState = () => {
    const { usersRef, user } = this.state;
    let disabledNewMessageAlert = user.info.disabledNewMessageAlert;
    usersRef
      .child(`${user.uid}/disabledNewMessageAlert`)
      .set(!disabledNewMessageAlert)
      .then(() => {
        user.info.disabledNewMessageAlert = !disabledNewMessageAlert;
        this.setState(
          {
            user: Object.assign({}, user),
          },
          () => this.props.setUser(Object.assign({}, user))
        );
      });
  };

  openModal = () => this.setState({ modal: true });
  closeModal = () => this.setState({ modal: false });

  openSettingModal = () => this.setState({ settingModal: true });
  closeSettingModal = () => this.setState({ settingModal: false });

  dropdownOptions = () => {
    let adminOptions = [
      {
        key: 'user',
        text: (
          <span>
            Signed in as <strong>{this.state.user.displayName}</strong>
          </span>
        ),
        disabled: true,
      },
      {
        key: 'admin',
        text: (
          <span>
            <Link to="/admin" style={{ color: 'black' }}>
              Admin Panel
            </Link>
          </span>
        ),
      },
      {
        key: 'Setting',
        text: (
          <span onClick={this.openSettingModal}>Notification Settings</span>
        ),
      },
      {
        key: 'signout',
        text: <span onClick={this.handleSignout}>Sign Out</span>,
      },
    ];
    let userOptions = [
      {
        key: 'user',
        text: (
          <span>
            Signed in as <strong>{this.state.user.displayName}</strong>
          </span>
        ),
        disabled: true,
      },
      {
        key: 'Setting',
        text: (
          <span onClick={this.openSettingModal}>Notification Settings</span>
        ),
      },
      {
        key: 'signout',
        text: <span onClick={this.handleSignout}>Sign Out</span>,
      },
    ];
    if (isAdmin(this.state.user)) {
      return adminOptions;
    }
    return userOptions;
  };

  handleSamlLogin = () => {
    const { samlProvider } = this.state;
    firebase
      .auth()
      .signInWithPopup(samlProvider)
      .then((result) => {
        // console.log('Result:', result);
        // console.log('IDP info:', result.additionalUserInfo.profile);
        // console.log("User's ID token:", result.user.getIdTokenResult());
        // console.log("token?", result.user.getIdTokenResult().idTokenResult.claims.firebase.sign_in_attributes)
        // User is signed in.
        // Identity provider data available in result.additionalUserInfo.profile,
        // or from the user's ID token obtained from result.user.getIdToken()
        // as an object in the firebase.sign_in_attributes custom claim
        // This is also available from result.user.getIdTokenResult()
        // idTokenResult.claims.firebase.sign_in_attributes.
      })
      .catch((err) => {
        console.error(err);
      });
  };

  handleSamlLogout = () => {};

  handleChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    if (file && file.type.match('image.*')) {
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        this.setState({ previewImage: reader.result });
      };
    }
  };

  handleCropImage = () => {
    if (this.AvatarEditor) {
      this.AvatarEditor.getImageScaledToCanvas().toBlob((blob) => {
        let imageUrl = URL.createObjectURL(blob);
        this.setState({
          croppedImage: imageUrl,
          blob,
        });
      });
    }
  };

  uploadCroppedImage = () => {
    const { storageRef, userRef, blob, metadata } = this.state;
    storageRef
      .child(`avatars/users/${userRef.uid}`)
      .put(blob, metadata)
      .then((snap) => {
        snap.ref.getDownloadURL().then((downloadUrl) => {
          this.setState({ uploadedCroppedImage: downloadUrl });
          this.changeAvatar();
        });
      });
  };

  changeAvatar = () => {
    this.state.userRef
      .updateProfile({
        photoURL: this.state.uploadedCroppedImage,
      })
      .then(() => {
        // console.log('photourl updated');
        this.closeModal();
      })
      .catch((err) => console.error(err));

    this.state.usersRef
      .child(this.state.user.uid)
      .update({ avatar: this.state.uploadedCroppedImage })
      .then(() => console.log('user avatar updated'))
      .catch((err) => console.error(err));
  };

  handleSignout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => console.log('signed out!'));
  };

  render() {
    const { user, modal, previewImage, croppedImage, settingModal } =
      this.state;
    // const { primaryColor } = this.props;

    return (
      <Grid>
        <Grid.Column>
          <Grid.Row style={{ padding: '1.2em', margin: 0 }}>
            {/* App Header */}
            <Header inverted floated="left" as="h2">
              {/* <Icon name="code" /> */}
              <Image src="/FleetForumLogo.png" style={{ width: '274px' }} />
              <Header.Content>Fleet Forum Chat</Header.Content>
            </Header>
            {/* User Options */}
            <Header style={{ padding: '0.25em' }} as="h4" inverted>
              <Dropdown
                trigger={
                  <span>
                    <Image src={user.photoURL} spaced="right" avatar />
                    {user.displayName}
                  </span>
                }
                options={this.dropdownOptions()}
              />
            </Header>
          </Grid.Row>
        </Grid.Column>
        {/* change user avatar */}
        <Modal basic open={modal} onClose={this.closeModal}>
          {/* <Modal.Header>Change Avatar</Modal.Header> */}
          <Modal.Content>
            <Input
              onChange={this.handleChange}
              fluid
              type="file"
              label="New Avatar"
              name="previewImage"
            />
            <Grid centered stackable columns={2}>
              <Grid.Row centered>
                <Grid.Column className="ui center aligned grid">
                  {previewImage && (
                    <AvatarEditor
                      image={previewImage}
                      ref={(node) => (this.AvatarEditor = node)}
                      // width={120}
                      // height={120}
                      border={50}
                      scale={1.2}
                    />
                  )}
                </Grid.Column>
                <Grid.Column>
                  {croppedImage && (
                    <Image
                      style={{ margin: '3.5em auto' }}
                      width={100}
                      height={100}
                      src={croppedImage}
                    />
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Content>
          <Modal.Actions>
            {croppedImage && (
              <Button color="green" inverted onClick={this.uploadCroppedImage}>
                <Icon name="save" /> Save Avatar
              </Button>
            )}
            <Button color="green" inverted onClick={this.handleCropImage}>
              <Icon name="image" /> Preview
            </Button>
            <Button color="red" inverted onClick={this.closeModal}>
              <Icon name="remove" /> Cancel
            </Button>
          </Modal.Actions>
        </Modal>
        <Modal basic open={settingModal} onClose={this.closeSettingModal}>
          <Modal.Header> Notification and Message Alert </Modal.Header>
          <Modal.Content>
            <Form>
              <Form.Group widths="equal" className="setting-notification">
                <Form.Field className="display-flex">
                  <Checkbox
                    onClick={this.setNotification}
                    checked={!user.info.disabledNotification}
                    toggle
                  />
                  <Label.Group tag>
                    <Label
                      className="title-label"
                      as="a"
                      onClick={this.setNotification}
                    >
                      Notification Alert
                    </Label>
                  </Label.Group>
                </Form.Field>
                <Form.Field className="display-flex">
                  <Checkbox
                    onClick={this.setSoundState}
                    checked={!user.info.disabledNewMessageAlert}
                    toggle
                  />
                  <Label.Group tag>
                    <Label
                      className="title-label"
                      as="a"
                      onClick={this.setSoundState}
                    >
                      New Message Alert
                    </Label>
                  </Label.Group>
                </Form.Field>
              </Form.Group>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button color="olive" inverted onClick={this.closeSettingModal}>
              <Icon name="remove" /> Close
            </Button>
          </Modal.Actions>
        </Modal>
      </Grid>
    );
  }
}
const matchStateToProps = (state) => ({
  user: state.user.currentUser,
});

export default connect(matchStateToProps, {
  setUser,
})(UserPanel);
