import * as actionTypes from '../actions/types';

const initialGroupUserEditModalState = {
  isOpenGroupUserEditModal: false,
  selectedGroupUser: {}
}
  
const GroupUserEditModalReducer = (state = initialGroupUserEditModalState, action) => {
  switch (action.type) {
    case actionTypes.SET_EDIT_GROUP_USER_MODAL:
      return {
        ...state,
        isOpenGroupUserEditModal: action.payload.isOpenGroupUserEditModal,
        selectedGroupUser: action.payload.selectedGroupUser
      };
    default:
      return state;
  }
};

export default GroupUserEditModalReducer