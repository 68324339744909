import { ItemMeta } from "semantic-ui-react";

export const FILTER_TYPE = {
    ALL: "ALL",
    NAME: "NAME",
    ORGANISATION: "ORGANISATION",
    AREASOFEXPERTISE: "AREASOFEXPERTISE",
}
export const FILTER_USER_ONLINE = {
    ALL: "ALL",
    ONLINE: "ONLINE",
}
export const STATEOPTIONS = [
    // {
    //   key: 0,
    //   text: "All",
    //   value: FILTER_TYPE.ALL,
    // },
    {
      key: 1,
      text: "Name",
      value: FILTER_TYPE.NAME,
    },
    {
      key: 2,
      text: "Organization",
      value: FILTER_TYPE.ORGANISATION,
    },
    {
      key: 3,
      text: "Areas Of Expertise",
      value: FILTER_TYPE.AREASOFEXPERTISE,
    },
]

export const filterUsers = (users, index, type, onlineState) => {
    index = index.replace(/['\']/, ''); // delete special character

    let re = new RegExp(index, 'i');
    let filteredUsers = users.filter((user) => {
        if(onlineState === FILTER_USER_ONLINE.ALL) {
            return true
        } else if(onlineState === FILTER_USER_ONLINE.ONLINE) {
            return user['status'] === "online"
        }
        return true
    })
    filteredUsers = filteredUsers.filter((user) => {
        switch (type) {
            case FILTER_TYPE.ALL:
                return true;
            case FILTER_TYPE.NAME:
                return user.name.search(re) !== -1;
            case FILTER_TYPE.ORGANISATION:
                return user.organisation.id.search(re) !== -1 || index === "";
            case FILTER_TYPE.AREASOFEXPERTISE:
                if(user.channels) {
                    let channel = Object.keys(user.channels).find((cKey) => index === cKey);
                    return channel || index === "";
                } else {
                    return index === "" || false;
                }
            default:
                break;
        }
        return true
    });

    return filteredUsers;
}


export const filterUsersByIds = (users, index, type, onlineState) => {
    index = index.replace(/['\']/, ''); // delete special character
    
    let re = new RegExp(index, 'i');
    let filteredUsers = users.filter((user) => {
        if(onlineState === FILTER_USER_ONLINE.ALL) {
            return true
        } else if(onlineState === FILTER_USER_ONLINE.ONLINE) {
            return user['status'] === "online"
        }
        return true;
    })
    filteredUsers = filteredUsers.filter((user) => {
        switch (type) {
            case FILTER_TYPE.ALL:
                return true;
            case FILTER_TYPE.NAME:
                return user.name.search(re) !== -1;
            case FILTER_TYPE.ORGANISATION:
                return user.organisation.search(re) !== -1 || index === "";
            case FILTER_TYPE.AREASOFEXPERTISE:
                if(user.channels) {
                    let channel = Object.keys(user.channels).find((cKey) => index === cKey);
                    return channel || index === "";
                } else {
                    return index === "" || false;
                }
            default:
                break;
        }
        return true
    });
    return filteredUsers;
}



export const buildChannelUrlName = function (name) {
    name = name.toLowerCase();
    name = name.split(' ').join('-');
    return name;
}