import React, { Component } from 'react';
import {
  Icon,
  Modal,
  // Input,
  Button,
  Grid,
  Image,
  List,
  Header,
  Table,
  Dropdown,
  Input,
} from 'semantic-ui-react';
import firebase from '../../../firebase';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  filterUsersByIds,
  STATEOPTIONS,
  FILTER_USER_ONLINE,
  FILTER_TYPE,
} from '../../../functions';
import MailService from '../../../service/mailSender';
import { buildChannelUrlName } from '../../../functions';
import { connect } from 'react-redux';
import Config from '../../../service/config';
// const friendOptions = [
//   {
//     key: 'All',
//     text: 'All',
//     value: FILTER_USER_ONLINE.ALL,
//     label: { color: 'orange', empty: true, circular: true },
//   },
//   {
//     key: 'Online',
//     text: 'Online',
//     value: FILTER_USER_ONLINE.ONLINE,
//     label: { color: 'green', empty: true, circular: true },
//   }
// ]

class AddMemberToArea extends Component {
  state = {
    users: [],
    orgs: [],
    areas: [],
    success: false,
    searchIndex: '',
    searchType: FILTER_TYPE.NAME,
    onlineState: FILTER_USER_ONLINE.ALL,
    usersRef: firebase.database().ref('users'),
    orgsRef: firebase.database().ref('organisations'),
    areasOfExpertiseRef: firebase.database().ref('channels'),
  };

  UNSAFE_componentWillMount() {
    const props = this.props;

    if (props.selectedArea) {
      let id = Object.getOwnPropertyNames('id');
      if (id) {
        this.getUsers(props.selectedArea);
      }
    }
    this.addListerner();
  }
  componentWillUnmount() {
    const { usersRef } = this.state;
    // usersRef.off('value');
  }

  getUsers = (selectedArea) => {
    const { usersRef } = this.state;

    usersRef.on('value', async (snap) => {
      let users = [];
      snap.forEach((user) => {
        let val = user.val();

        let chanel = val.channels && val.channels[selectedArea.id];

        if (chanel) {
          val.hasChannel = true;
        } else {
          val.hasChannel = false;
        }

        if (val) {
          users.push(val);
        }
      });

      this.setState({
        users: users,
      });
    });
  };

  addListerner = () => {
    const { orgsRef, areasOfExpertiseRef } = this.state;
    orgsRef.on('value', (snap) => {
      let orgs = [];
      snap.forEach((childSnap) => {
        orgs.push(childSnap.val());
      });
      this.setState({
        orgs: orgs,
      });
    });
    areasOfExpertiseRef.on('value', (snap) => {
      let areas = [];
      snap.forEach((childSnap) => {
        areas.push(childSnap.val());
      });
      this.setState({
        areas: areas,
      });
    });
  };

  displayMemberUser = (users) => {
    return users.map((user, index) => (
      <List.Item key={index}>
        <Image avatar src={user.avatar} />
        <List.Content>
          <List.Header>{user.name}</List.Header>
          {/* An excellent companion */}
        </List.Content>
        <div className="addmember_to_area-userlist-action">
          {
            <Button.Group basic size="small">
              <Button
                icon="trash alternate outline"
                onClick={() => this.removeMember(user)}
              />
            </Button.Group>
          }
        </div>
      </List.Item>
    ));
  };

  displayUser = (users) =>
    users.map((user, index) => (
      <List.Item key={index}>
        <Image avatar src={user.avatar} />
        <List.Content>
          <List.Header>{user.name}</List.Header>
          {/* An excellent companion */}
        </List.Content>
        <div className="addmember_to_area-userlist-action">
          {/* {
          user.hasChannel ? 
            <Button.Group basic size='small'>
              <Button icon='trash alternate outline' />
            </Button.Group>
          : */}
          <Button.Group basic size="small">
            <Button icon="add" onClick={() => this.addMember(user)} />
          </Button.Group>
          {/* } */}
        </div>
      </List.Item>
    ));

  addMember = (user) => {
    const { usersRef } = this.state;
    const area = this.props.selectedArea;

    console.log('user', user);

    usersRef
      .child(`${user.key}/channels/${area.id}`)
      .update({ ...area, disabled: false })
      .then(() => {
        MailService.sendMail(this.props.currentUser, {
          to: user.email,
          subject: 'Fleet Forum Chat notification - ' + area.name,
          content: `Dear ${user.name},
            You have been added to the ${area.name} channel.
            Please click <a href="${Config.frontendUrl}/#${buildChannelUrlName(
            area.name
          )}">here</a> to access.
          `,
        });
        toast('Added sucessfully!');
      });
  };

  removeMember = (user) => {
    const { usersRef } = this.state;
    const area = this.props.selectedArea;

    usersRef
      .child(`${user.key}/channels/${area.id}`)
      .remove()
      .then(() => {
        toast('Removed!');
      });
  };

  setSearchType = (e, data) => {
    this.setState({
      searchType: data.value,
    });
  };

  handleSearchChange = (e) => {
    let value = e.target.value;
    this.setState({
      activeIndex: 1,
      searchIndex: value,
    });
  };

  render() {
    const { open, onClose, selectedArea } = this.props;
    const {
      users,
      searchType,
      orgs,
      areas,
      searchIndex,
      onlineState,
    } = this.state;
    // console.log("users", users)
    let geneUsers = users.filter((user) => !user.hasChannel); // user who no have channel
    let memberUsers = users.filter((user) => user.hasChannel); // user who have channel

    geneUsers = filterUsersByIds(
      geneUsers,
      searchIndex,
      searchType,
      onlineState
    );
    memberUsers = filterUsersByIds(
      memberUsers,
      searchIndex,
      searchType,
      onlineState
    );

    // search options
    let orgsOptions = [];
    orgsOptions.push({
      key: 0,
      text: 'All',
      value: '',
    });
    orgs.forEach((org, index) => {
      orgsOptions.push({
        key: index + 1,
        text: org.name,
        value: org.id,
      });
    });
    let areasOptions = [];
    areasOptions.push({
      key: 0,
      text: 'All',
      value: '',
    });
    areas.forEach((area, index) => {
      areasOptions.push({
        key: index + 1,
        text: area.name,
        value: area.id,
      });
    });
    const trigger = <span>Name </span>;

    return (
      <>
        <Modal
          basic
          open={open}
          onClose={() => {
            onClose();
          }}
          className="addmember_to_area"
        >
          {/* <Card> */}

          <Modal.Header>Add Member to {selectedArea.name}</Modal.Header>

          <Modal.Content>
            <Grid columns="equal">
              {/* no  members */}
              <Grid.Column width="16">
                <Table definition className="search_table">
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell width="3" className="pl-5">
                        Search By
                      </Table.Cell>
                      <Table.Cell className="pl-5" width="3">
                        <Dropdown
                          placeholder=""
                          simple
                          item
                          selectedLabel=""
                          options={STATEOPTIONS}
                          trigger={trigger}
                          onChange={this.setSearchType}
                        />
                      </Table.Cell>
                      <Table.Cell className="search__input" width="5">
                        {searchType === FILTER_TYPE.ORGANISATION && (
                          <Dropdown
                            placeholder="Organizations"
                            search
                            selection
                            options={orgsOptions}
                            onChange={(e, data) => {
                              e.target.value = data.value;
                              this.handleSearchChange(e);
                            }}
                          />
                        )}
                        {searchType === FILTER_TYPE.AREASOFEXPERTISE && (
                          <Dropdown
                            placeholder="Areas of expertise"
                            search
                            selection
                            options={areasOptions}
                            onChange={(e, data) => {
                              e.target.value = data.value;
                              this.handleSearchChange(e);
                            }}
                          />
                        )}
                        {searchType === FILTER_TYPE.NAME && (
                          <Input
                            size="mini"
                            icon="search"
                            name="searchTerm"
                            placeholder="Search Users"
                            className="search-name"
                            onChange={this.handleSearchChange}
                          />
                        )}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Column>
              <Grid.Column width="8" className="addmember_to_area-userlist">
                <Header>Users</Header>
                <List celled>
                  {geneUsers.length > 0 && this.displayUser(geneUsers)}
                </List>
              </Grid.Column>

              {/* members */}
              <Grid.Column width="8" className="addmember_to_area-userlist">
                <Header>Members</Header>
                <List celled>
                  {memberUsers.length > 0 &&
                    this.displayMemberUser(memberUsers)}
                </List>
              </Grid.Column>
            </Grid>
          </Modal.Content>
          {/* close */}
          <Modal.Actions>
            <Button
              color="red"
              inverted
              onClick={() => {
                onClose();
              }}
            >
              <Icon name="close" /> Close
            </Button>
          </Modal.Actions>
          {/* </Card> */}
          <ToastContainer></ToastContainer>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});
export default connect(mapStateToProps)(AddMemberToArea);
