import React from 'react';
import { connect } from 'react-redux';
import Notification from 'react-web-notification';
import firebase from '../../firebase';
import like from '../../assets/sound/sound.mp3';

class MessageNotification extends React.Component {
  messages = [];
  state = {
    newMessages: [],
    onMessage: false,
    sound: new Audio(like),
    user: this.props.currentUser,
    browserBlockedNotification: false,
    usersRef: firebase.database().ref('users'),
    messageRef: firebase.database().ref('messages'),
    channelsRef: firebase.database().ref('channels'),
    privateMessagesRef: firebase.database().ref('privateMessages'),
  };
  UNSAFE_componentWillMount() {
    this.addListener();
    setTimeout(() => {
      this.setState({
        onMessage: true,
      });
    }, 4000);
  }

  componentWillUnmount() {
    this.removeListener();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (Object.is(this.state.user, nextProps.currentUser)) {
      this.setState({
        user: nextProps.currentUser,
      });
    }
  }

  addListener = () => {
    const { messageRef, user, privateMessagesRef, usersRef, channelsRef } =
      this.state;

    if (user && user.info && user.info.channels) {
      // for areas of expertise
      Object.keys(user.info.channels).map((key) => {
        if (!user.info.channels[key].disabled) {
          return messageRef
            .child(`${key}`)
            .limitToLast(1)
            .on('child_added', (snap) => {
              this.addNewMessage(snap);
            });
        }
        return null;
      });
      // public channels
      channelsRef.once('value', (snap) => {
        let val = snap.val();
        Object.keys(val).map((key) => {
          if (val[key].public) {
            return messageRef
              .child(key)
              .limitToLast(1)
              .on('child_added', (messageSnap) => {
                this.addNewMessage(messageSnap);
              });
          }
          return null;
        });
      });
    }
    // for group
    if (user.info) {
      user.info.organisation &&
        messageRef
          .child(user.info.organisation)
          .limitToLast(1)
          .on('child_added', (snap) => {
            this.addNewMessage(snap);
          });
    }
    if (user.info) {
      // for private messsage
      usersRef.once('value', (snap) => {
        let users = snap.val();
        Object.keys(users).map((userId) => {
          if (userId !== user.uid) {
            return privateMessagesRef
              .child(`${userId}/${user.uid}`)
              .limitToLast(1)
              .on('child_added', (snap) => {
                this.addNewMessage(snap);
              });
          }
          return null;
        });
        Object.keys(users).map((userId) => {
          if (userId !== user.uid) {
            return privateMessagesRef
              .child(`${user.uid}/${userId}`)
              .limitToLast(1)
              .on('child_added', (snap) => {
                this.addNewMessage(snap);
              });
          }
          return null;
        });
      });
    }
  };
  addNewMessage = (val) => {
    if (val) {
      val = val.val();
      const { onMessage } = this.state;
      if (onMessage) {
        let messages = this.messages;
        messages.push(val);
        messages = messages.filter((message) => {
          return message.user.id !== this.state.user.uid;
        });
        this.setState({
          newMessages: messages,
        });
      }
    }
  };

  removeListener = () => {
    const { messageRef, user, privateMessagesRef, channelsRef, usersRef } =
      this.state;
    if (user.info && user.info.channels) {
      // channels
      Object.keys(user.info.channels).map((key) => {
        return messageRef.child(`${key}`).off();
      });
    }
    privateMessagesRef.off();
    channelsRef.off();
    usersRef.off();
  };

  onError = (e) => {
    console.error('MessageNotification', e);
  };
  onClick = () => {
    window.location.href = '/';
  };
  onClose = () => {};

  onShow = () => {
    // this.removeNewMessages()
  };

  removeNewMessages = () => {
    this.messages = [];
    this.setState({
      newMessages: [],
    });
  };

  onPermissionDenied = () => {
    // alert("permission of Notification is denied")
    this.setState({
      browserBlockedNotification: true,
    });
  };
  notSupported = () => {
    console.log('this browser does not support Notifications');
    this.setState({
      browserBlockedNotification: true,
    });
  };
  onPermissionGranted = () => {};

  play = () => {
    if (!this.state.user.info.disabledNewMessageAlert) {
      this.state.sound.play();
    }
  };
  render() {
    let options = {
      body: 'New Message',
      icon: '',
    };
    const { user, newMessages } = this.state;

    setTimeout(this.removeNewMessages, 400);

    let title = 'New Message arrived from ';

    return (
      newMessages.length > 0 &&
      newMessages.map((newMessage, i) => {
        // console.log("newMessages", newMessages);

        if (this.state.browserBlockedNotification) {
          this.play();
        }
        if (!user.info.disabledNotification) {
          options.body = newMessage.content;
          return (
            <Notification
              onPermissionGranted={this.onPermissionGranted}
              notSupported={this.notSupported}
              onPermissionDenied={this.onPermissionDenied}
              onShow={() => this.onShow()}
              onClick={this.onClick}
              onClose={this.onClose}
              onError={this.onError}
              timeout={3000}
              title={title + newMessage.user.name}
              options={options}
              key={i}
            />
          );
        } else {
          return <></>;
        }
      })
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(MessageNotification);
