import React, { Component } from 'react';
import {
  Icon,
  Label,
  Segment,
  Header,
  Accordion,
  Table,
  List,
  Image,
  Input,
  Dropdown,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import defaultUserAvatar from '../../assets/imgs/defaultUserAvatar.png';
import firebase from '../../firebase';
import {
  filterUsers,
  STATEOPTIONS,
  FILTER_USER_ONLINE,
  FILTER_TYPE,
} from '../../functions';
import {
  setSearchUserIndex,
  setSelectedUser,
  setCurrentChannel,
  setPrivateChannel,
} from '../../actions';

const friendOptions = [
  {
    key: 'All',
    text: 'All',
    value: FILTER_USER_ONLINE.ALL,
    label: { color: 'orange', empty: true, circular: true },
  },
  {
    key: 'Online',
    text: 'Online',
    value: FILTER_USER_ONLINE.ONLINE,
    label: { color: 'green', empty: true, circular: true },
  },
];

class AllUsers extends Component {
  state = {
    user: this.props.currentUser,
    users: [],
    users1: [],
    orgs: [],
    areas: [],
    usersCount: 0,
    messages: {},
    activeChannel: '',
    searchIndex: this.props.searchIndex,
    searchType: this.props.searchType,
    activeIndex: 0,
    onlineState: FILTER_USER_ONLINE.ALL,
    messagesRef: firebase.database().ref('messages'),
    usersRef: firebase.database().ref('users'),
    connectedRef: firebase.database().ref('.info/connected'),
    presenceRef: firebase.database().ref('presence'),
    privateMessagesRef: firebase.database().ref('privateMessages'),
    orgsRef: firebase.database().ref('organisations'),
    channelsRef: firebase.database().ref('channels'),
    filterState: false,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!Object.is(nextProps.searchIndex, this.state.searchIndex)) {
      this.setState({
        searchIndex: nextProps.searchIndex,
      });
    }
    if (!Object.is(nextProps.searchType, this.state.searchType)) {
      this.setState({
        searchType: nextProps.searchType,
      });
    }
    if (!Object.is(nextProps.onlineState, this.state.onlineState)) {
      this.setState({
        onlineState: nextProps.onlineState,
      });
    }
  }

  componentDidMount() {
    if (this.state.user) {
      this.addListeners(this.state.user.uid);
    }
  }

  componentWillUnmount() {
    // this.removeListeners();
  }

  handleSearchChange = (e) => {
    let value = e.target.value;
    // console.log(e.target.value)
    this.setState(
      {
        activeIndex: 1,
      },
      () => {
        if (this.state.users.length == 0) {
          this.getAllUsers(this.state.user.uid);
        }
      }
    );
    this.props.setSearchUserIndex(
      value,
      this.state.searchType,
      this.state.onlineState
    );
  };

  removeListeners = () => {
    this.state.usersRef.off();
    this.state.presenceRef.off();
    this.state.connectedRef.off();
  };

  getAllUsers = (currentUserId) => {
    let loadedUsers = [];

    this.state.usersRef.on('child_added', (snap) => {
      if (currentUserId !== snap.key) {
        // for new messages
        this.addMessageListenerForUsers(snap.key);

        let user = snap.val();
        user['uid'] = snap.key;
        user['status'] = 'offline';

        if (user.organisation) {
          this.state.orgsRef
            .child(user.organisation)
            .once('value', (orgSnap) => {
              user.organisation = orgSnap.val();
            });
        }
        loadedUsers.push(user);
      }
    });

    this.setState({ users: loadedUsers }, () => {
      this.state.presenceRef.on('child_added', (snap) => {
        if (currentUserId !== snap.key) {
          this.addStatusToUser(snap.key, this.state.user);
        }
      });

      this.state.presenceRef.on('child_removed', (snap) => {
        if (currentUserId !== snap.key) {
          this.addStatusToUser(snap.key, false, this.state.user);
        }
      });
    });
  };

  addListeners = (currentUserId) => {
    let usersCount = 0;

    this.state.usersRef.on('child_added', (snap) => {
      if (currentUserId !== snap.key) {
        // for new messages
        this.addMessageListenerForUsers(snap.key);
      }
      usersCount++;
      this.setState({
        usersCount,
      });
    });

    this.state.connectedRef.on('value', (snap) => {
      if (snap.val() === true) {
        const ref = this.state.presenceRef.child(currentUserId);
        ref.set(true);
        ref.onDisconnect().remove((err) => {
          if (err !== null) {
            console.error(err);
          }
        });
      }
    });

    this.state.presenceRef.on('child_added', (snap) => {
      if (currentUserId !== snap.key) {
        this.addStatusToUser(snap.key);
      }
    });

    this.state.presenceRef.on('child_removed', (snap) => {
      if (currentUserId !== snap.key) {
        this.addStatusToUser(snap.key, false);
      }
    });

    this.state.orgsRef.on('value', (snap) => {
      let orgs = [];
      snap.forEach((childSnap) => {
        orgs.push(childSnap.val());
      });
      this.setState({
        orgs: orgs,
      });
    });

    this.state.channelsRef.on('value', (snap) => {
      let areas = [];
      snap.forEach((childSnap) => {
        if (childSnap.val().isCustom !== true) {
          areas.push(childSnap.val());
        }
      });
      this.setState({
        areas: areas,
      });
    });
  };

  addMessageListenerForUsers = (parterId) => {
    const channelID = this.getChannelId(parterId);
    this.state.privateMessagesRef.child(channelID).on('value', (snap) => {
      let val = snap.val();
      let dbMessages = [];
      if (val) {
        dbMessages = Object.keys(val).map((key) => {
          return val[key];
        });
        dbMessages = dbMessages.filter(
          (message) => message.user.id !== this.state.user.uid
        ); // delete my messages
        let oldMessages = this.state.messages;

        if (!oldMessages[parterId]) {
          // init
          oldMessages[parterId] = {};
          oldMessages[parterId].totalCount = dbMessages.length;
          oldMessages[parterId].newMessagesCount = 0;
        } else {
          // new message
          oldMessages[parterId].newMessagesCount =
            dbMessages.length - oldMessages[parterId].totalCount;
        }

        this.setState(
          {
            messages: oldMessages,
          },
          () => {
            if (this.state.activeChannel === parterId) {
              this.makeReadedMessage(parterId);
            }
          }
        );
      }
    });
  };

  makeReadedMessage = (key) => {
    let messages = this.state.messages;
    if (messages[key]) {
      messages[key].totalCount += messages[key].newMessagesCount;
      messages[key].newMessagesCount = 0;
      this.setState({
        messages,
      });
    }
  };

  getNewMessageCount = (user) => {
    let messageData = this.state.messages[user.uid];
    if (messageData && messageData.newMessagesCount > 0) {
      return messageData.newMessagesCount;
    }
  };

  changeChannel = (user) => {
    const channelId = this.getChannelId(user.uid);
    const channelData = {
      id: channelId,
      name: user.name,
    };
    this.props.setCurrentChannel(channelData);
    this.props.setPrivateChannel(true);
    this.props.setSelectedUser(user);
    this.setActiveChannel(user.uid);
  };

  getChannelId = (userId) => {
    const currentUserId = this.state.user.uid;
    return userId < currentUserId
      ? `${userId}/${currentUserId}`
      : `${currentUserId}/${userId}`;
  };

  addStatusToUser = (userId, connected = true) => {
    const updatedUsers = this.state.users.reduce((acc, user) => {
      if (user.uid === userId) {
        user['status'] = `${connected ? 'online' : 'offline'}`;
      }
      return acc.concat(user);
    }, []);
    this.setState({ users: updatedUsers });
  };

  isUserOnline = (user) => user.status === 'online';

  setActiveChannel = (userId) => {
    this.setState({ activeChannel: userId });
  };
  setActiveIndex = (event, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;
    this.setState({ activeIndex: newIndex });
  };
  setSearchType = (e, data) => {
    this.setState({
      searchType: data.value,
      activeIndex: 1,
    });
    this.props.setSearchUserIndex('', data.value, this.state.onlineState);
  };
  setFilterOnlineState = (e, data) => {
    this.getAllUsers(this.state.user.uid);

    if (data.value === 'ONLINE') {
      this.setState({
        filterState: true,
      });
    } else {
      this.setState({
        filterState: false,
      });
    }
    this.setState({
      onlineState: data.value,
    });
    this.props.setSearchUserIndex(
      this.state.searchIndex,
      data.value,
      data.value
    );
  };
  render() {
    const {
      users,
      activeChannel,
      searchIndex,
      activeIndex,
      searchType,
      onlineState,
      orgs,
      areas,
      usersCount,
      filterState,
    } = this.state;

    let sortedUsers = users.sort((a, b) => {
      // Use toUpperCase() to ignore character casing
      const bandA = a.name.toUpperCase();
      const bandB = b.name.toUpperCase();

      let comparison = 0;
      if (bandA > bandB) {
        comparison = 1;
      } else if (bandA < bandB) {
        comparison = -1;
      }
      return comparison;
    });

    let filteredUsers = filterUsers(
      sortedUsers,
      searchIndex,
      searchType,
      onlineState
    );

    let orgsOptions = [];
    orgsOptions.push({
      key: 0,
      text: 'All',
      value: '',
    });
    orgs.forEach((org, index) => {
      orgsOptions.push({
        key: index + 1,
        text: org.name,
        value: org.id,
      });
    });
    let areasOptions = [];
    areasOptions.push({
      key: 0,
      text: 'All',
      value: '',
    });
    areas.forEach((area, index) => {
      areasOptions.push({
        key: index + 1,
        text: area.name,
        value: area.id,
      });
    });

    return (
      <Segment loading={!filteredUsers}>
        {/* <Menu.Menu className="menu" style={{height: "52%", overflowY: "hidden"}}> */}
        <Header as="h3" attached="top">
          {this.state.onlineState === FILTER_USER_ONLINE.ALL ? 'All' : 'Online'}
          Users ({filterState ? filteredUsers.length : usersCount}) &nbsp;
          <span style={{ float: 'right' }}>
            Filter{' '}
            <Dropdown
              inline
              options={friendOptions}
              defaultValue={friendOptions[0].value}
              onChange={this.setFilterOnlineState}
            />
          </span>
        </Header>
        <Table definition className="search__table">
          <Table.Body>
            <Table.Row>
              <Table.Cell width="6">
                Search By &nbsp;
                <Dropdown
                  placeholder="Search By"
                  simple
                  item
                  options={STATEOPTIONS}
                  onChange={this.setSearchType}
                />
              </Table.Cell>
              <Table.Cell className="search__input">
                {searchType === FILTER_TYPE.ORGANISATION && (
                  <Dropdown
                    placeholder="Organizations"
                    search
                    selection
                    options={orgsOptions}
                    onChange={(e, data) => {
                      e.target.value = data.value;
                      this.handleSearchChange(e);
                    }}
                  />
                )}
                {searchType === FILTER_TYPE.AREASOFEXPERTISE && (
                  <Dropdown
                    placeholder="Areas of expertise"
                    search
                    selection
                    options={areasOptions}
                    onChange={(e, data) => {
                      e.target.value = data.value;
                      this.handleSearchChange(e);
                    }}
                  />
                )}
                {searchType === FILTER_TYPE.NAME && (
                  <Input
                    // size="mini"
                    icon="search"
                    name="searchTerm"
                    placeholder="Search Users"
                    onChange={this.handleSearchChange}
                  />
                )}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <Accordion styled attached="true">
          <Accordion.Title
            active={activeIndex === 1}
            index={1}
            onClick={(e, titleProps) => {
              this.setActiveIndex(e, titleProps);
              if (users.length == 0) {
                this.getAllUsers(this.state.user.uid);
              }
            }}
          >
            <Icon name="dropdown" />
            <Icon circular color="green" name="users" />
            All Users
          </Accordion.Title>
          <Accordion.Content
            active={activeIndex === 1}
            className="channels-panel"
          >
            <List className="user_list" style={{ overflowY: 'auto' }}>
              {filteredUsers.length > 0 &&
                filteredUsers.map((user, index) => (
                  <List.Item
                    key={index}
                    active={user.uid === activeChannel}
                    onClick={() => {
                      this.changeChannel(user);
                      this.makeReadedMessage(user.uid);
                    }}
                    style={{ opacity: 1, fontStyle: 'italic' }}
                    className="channels-panel-item"
                    disabled={user.state === false}
                  >
                    {user.avatar ? (
                      <Image avatar src={user.avatar} />
                    ) : (
                      <Image avatar src={defaultUserAvatar} name={'user'} />
                    )}
                    <List.Content className="right__panel__user__name">
                      {this.getNewMessageCount(user) && (
                        <Label color="red">
                          {this.getNewMessageCount(user)}
                        </Label>
                      )}
                      {user.name}
                    </List.Content>
                    <Icon
                      name="circle"
                      color={this.isUserOnline(user) ? 'green' : 'red'}
                      className="float-right pt-6"
                    />
                  </List.Item>
                ))}
            </List>
          </Accordion.Content>
        </Accordion>
        {/* </Menu.Menu> */}
      </Segment>
    );
  }
}

const mapStateToProps = (state) => ({
  searchIndex: state.searchUser.userSearchIndex,
  searchType: state.searchUser.userSearchType,
  onlineState: state.searchUser.userSearchOnline,
});

const mapDispatchToProps = {
  setCurrentChannel,
  setPrivateChannel,
  setSearchUserIndex,
  setSelectedUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(AllUsers);
