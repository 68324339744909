import * as actionTypes from './types';

export const setUser = (user) => {
  return {
    type: actionTypes.SET_USER,
    payload: {
      currentUser: user,
    },
  };
};

export const clearUser = () => {
  return {
    type: actionTypes.CLEAR_USER,
  };
};

export const setCurrentChannel = (channel) => {
  return {
    type: actionTypes.SET_CURRENT_CHANNEL,
    payload: {
      currentChannel: channel,
    },
  };
};

export const setPrivateChannel = (isPrivateChannel) => {
  return {
    type: actionTypes.SET_PRIVATE_CHANNEL,
    payload: {
      isPrivateChannel,
    },
  };
};

export const setUserPosts = (userPosts) => {
  return {
    type: actionTypes.SET_USER_POSTS,
    payload: {
      userPosts,
    },
  };
};

// color actions
export const setColors = (primaryColor, secondaryColor) => {
  return {
    type: actionTypes.SET_COLORS,
    payload: {
      primaryColor,
      secondaryColor,
    },
  };
};

export const setDisplayStateOfMetaPanel = (state) => {
  return {
    type: actionTypes.SET_DISPLAY_STATE_OF_METAPANEL,
    payload: {
      state,
    },
  };
};

export const setDisplayStateOfMessages = (state) => {
  return {
    type: actionTypes.SET_DISPLAY_STATE_OF_MESSAGES,
    payload: {
      state,
    },
  };
};

export const setDisplayStateOfORGTable = (state) => {
  return {
    type: actionTypes.SET_DISPLAY_STATE_OF_ORGTABLE,
    payload: {
      state,
    },
  };
};

export const setActiveItemOfAdminSideBar = (state) => {
  return {
    type: actionTypes.SET_ACTIVE_ITEM_OF_ADMIN_SIDEBAR,
    payload: {
      sideItemName: state,
    },
  };
};
export const setCloseModal = () => {
  return {
    type: actionTypes.SET_MODAL_SHOW_STATE,
    payload: {
      isOpenModal: false,
    },
  };
};
export const setShowModal = (org) => {
  return {
    type: actionTypes.SET_MODAL_SHOW_STATE,
    payload: {
      isOpenModal: true,
      selectedOrg: org,
    },
  };
};

export const setCloseAreasOfExpertiseModal = () => {
  return {
    type: actionTypes.SET_MODAL_HIDE_AREAS_OF_EXPERTISE,
    payload: {
      isOpenAreaOfExpertiseModal: false,
    },
  };
};
export const setShowAreasOfExpertiseModal = (selectedAreaOfExpertise) => {
  return {
    type: actionTypes.SET_MODAL_SHOW_AREAS_OF_EXPERTISE,
    payload: {
      selectedAreaOfExpertise,
    },
  };
};

export const setEditUserModal = (value, selectedUser = {}) => {
  return {
    type: actionTypes.SET_EDIT_USER_MODAL,
    payload: {
      isOpenUserEditModal: value,
      selectedUser: selectedUser,
    },
  };
};

export const setEditGroupUserModal = (value, selectedGroupUser = {}) => {
  return {
    type: actionTypes.SET_EDIT_GROUP_USER_MODAL,
    payload: {
      isOpenGroupUserEditModal: value,
      selectedGroupUser: selectedGroupUser,
    },
  };
};

export const setSearchUserIndex = (value, type, isOnline) => {
  return {
    type: actionTypes.SET_SEARCH_USER,
    payload: {
      userSearchIndex: value,
      userSearchType: type,
      userSearchOnline: isOnline,
    },
  };
};

export const setSelectedUser = (value) => {
  return {
    type: actionTypes.SET_SELECTED_USER,
    payload: {
      selectedUser: value,
    },
  };
};
