import * as actionTypes from '../actions/types';

const initialOrgEditModalState = {
  isOpenAreaOfExpertiseModal: false,
  selectedAreaOfExpertise: null
}
  
const AreaOfExpertiseEditModalReducer = (state = initialOrgEditModalState, action) => {
  switch (action.type) {
    case actionTypes.SET_MODAL_HIDE_AREAS_OF_EXPERTISE:
      return {
        ...state,
        isOpenAreaOfExpertiseModal: action.payload.isOpenAreaOfExpertiseModal
      };
    case actionTypes.SET_MODAL_SHOW_AREAS_OF_EXPERTISE:
      return {
        ...state,
        isOpenAreaOfExpertiseModal: true,
        selectedAreaOfExpertise: action.payload.selectedAreaOfExpertise
      };
    default:
      return state;
  }
};

export default AreaOfExpertiseEditModalReducer