import React from 'react';
import { Grid, Button, Header, Message, Image } from 'semantic-ui-react';
// import { Link } from 'react-router-dom';
import firebase from '../../firebase';
import { setUser } from '../../actions/index';
import { buildChannelUrlName } from '../../functions';
import { connect } from 'react-redux';
import axios from 'axios';
import { serverUrl } from '../../service/config';

class SSO extends React.Component {
  state = {
    email: '',
    password: '',
    errors: [],
    loading: false,
    user: null,
    samlProvider: new firebase.auth.SAMLAuthProvider('saml.ff-demo-chat-local'),
    usersRef: firebase.database().ref('users'),
    channelsRef: firebase.database().ref('channels'),
    orgsRef: firebase.database().ref('organisations'),
    areasOfExpertiseNames: [],
  };

  componentWillUnmount() {
    this.state.usersRef.off();
    this.state.channelsRef.off('value');
  }

  saveUser = (samlUser) => {
    console.log(
      'this.props.setUser(samlUser):',
      Object.assign({}, samlUser.user)
    );
    // let user = Object.assign({}, samlUser.user)
    // this.props.setUser(user);
    let newUser = {
      email: samlUser.additionalUserInfo.profile.email,
      name: `${samlUser.additionalUserInfo.profile.firstName} ${samlUser.additionalUserInfo.profile.lastName}`,
      avatar: samlUser.additionalUserInfo.profile.profilePic,
      // organisation: samlUser.additionalUserInfo.profile.organization,
      key: samlUser.user.uid,
      samlId: samlUser.additionalUserInfo.profile.uid,
      state: true,
    };

    const { usersRef } = this.state;
    return new Promise((resolve, rejected) => {
      if (samlUser.additionalUserInfo.isNewUser) {
        // if is new user
        usersRef
          .child(samlUser.user.uid)
          .set(newUser)
          .then(() => {
            console.log('created New User');
            resolve();
          })
          .catch((err) => {
            console.error(err);
            rejected(err);
          });
      } else {
        // if old user
        // delete newUser.organisation;
        delete newUser.state;
        delete newUser.avatar;

        usersRef
          .child(samlUser.user.uid)
          .update(newUser, () => {
            // console.log('updated User', Object.assign({}, samlUser));
            resolve();
          })
          .catch((error) => {
            console.error('updated User error', error);
            rejected(error);
          });
      }
    });
  };

  saveOrg = (samlUser) => {
    let orgs = [];
    let newOrg = {
      name: samlUser.additionalUserInfo.profile.organization,
      createdBy: {
        name: `${samlUser.additionalUserInfo.profile.firstName} ${samlUser.additionalUserInfo.profile.lastName}`,
        avatar: samlUser.additionalUserInfo.profile.profilePic,
      },
      details: 'new organization',
      state: true,
    };
    const { orgsRef, usersRef } = {
      orgsRef: firebase.database().ref('organisations'),
      usersRef: firebase.database().ref('users'),
    };
    return new Promise((resolve, rejected) => {
      orgsRef.once('value', (snap) => {
        let obj = snap.val();
        if (obj) {
          orgs = Object.keys(obj).map((key) => {
            return {
              ...obj[key],
            };
          });
        }

        const oldOrg = orgs.find((org) => {
          return org.name === newOrg.name;
        });
        if (!oldOrg) {
          // if dont exists org
          //  create new org
          let key = orgsRef.push().key;
          newOrg = {
            ...newOrg,
            id: key,
          };
          orgsRef.child(key).update(newOrg, (res) => {
            console.log('created new org', res);
          });
          //  update user org id
          usersRef
            .child(`${samlUser.user.uid}/organisation`)
            .set(key)
            .then(() => {
              resolve();
            })
            .catch((error) => {
              rejected(error);
            });
        } else {
          usersRef
            .child(`${samlUser.user.uid}/organisation`)
            .set(oldOrg.id)
            .then(() => {
              resolve();
            })
            .catch((error) => {
              rejected(error);
            });
        }
      });
    });
  };

  async getAreaOfExpertise(samlUser) {
    var userId = samlUser.user.info.samlId;
    var area_of_expertise;
    await axios.get(`${serverUrl}/api/expertise/${userId}`).then((respose) => {
      respose.data.custom_fields.map((item) => {
        if (item.label === 'Areas of Expertise') {
          this.setState({
            areasOfExpertiseNames: item.textValue,
          });

          area_of_expertise = item.textValue;
        }
      });
    });
    return area_of_expertise;
  }

  async saveAreaOfExpertise(samlUser) {
    console.log('samlUser', samlUser);
    var area_of_expertise;
    area_of_expertise = await this.getAreaOfExpertise(samlUser);
    let areasOfExpertiseNames =
      samlUser.additionalUserInfo.profile.areasOfExpertise;

    let channels = [];
    this.state.channelsRef.on('child_added', (snap) => {
      let val = snap.val();
      channels.push(val);
    });
    areasOfExpertiseNames = JSON.parse(areasOfExpertiseNames);
    const { channelsRef, usersRef } = {
      orgsRef: firebase.database().ref('organisations'),
      usersRef: firebase.database().ref('users'),
      channelsRef: firebase.database().ref('channels'),
    };
    return new Promise((resolve, rejected) => {
      channelsRef.once('value', (snap) => {
        let channels = [];
        let userChannels = {};
        let existingUserChannels = {};
        let val = snap.val();
        if (val) {
          channels = Object.keys(val).map((key) => val[key]);
        }

        areasOfExpertiseNames.map((areaOfExpertise) => {
          const currentExistingChannels = channels.find((channel) => {
            return channel.name === areaOfExpertise;
          });
          existingUserChannels[currentExistingChannels.id] = {
            ...currentExistingChannels,
          };
        });

        console.log('existingUserChannels', existingUserChannels);

        Object.keys(existingUserChannels).map((key) => {
          return this.state.usersRef
            .child(`${samlUser.user.uid}/channels/${key}`)
            .remove()
            .then(() => {});
        });

        // console.log("areasOfExpertiseNames", areasOfExpertiseNames)
        area_of_expertise.map((name) => {
          const oldArea = channels.find((channel) => {
            return channel.name === name;
          });

          if (!oldArea) {
            // create new channel
            const key = this.state.channelsRef.push().key;
            let newAreasOfExpertise = {
              id: key,
              name: name,
              details: 'New channel by default',
              state: true,
              logo: 'logo',
              refUrl: buildChannelUrlName(name),
              createdBy: {
                name: samlUser.user.displayName,
                avatar: samlUser.user.photoURL,
              },
            };
            // add userChannel
            userChannels[key] = {
              ...newAreasOfExpertise,
              disabled: false,
            };
            return channelsRef
              .child(key)
              .update(newAreasOfExpertise)
              .then(() => {
                console.log('added new chanel named', name);
              })
              .catch((err) => {
                console.error(err);
              });
          } else {
            return (userChannels[oldArea.id] = {
              ...oldArea,
            });
          }
        });

        // update user channel
        Object.keys(userChannels).map((key) => {
          return usersRef
            .child(`${samlUser.user.uid}/channels/${key}`)
            .transaction((channel) => {
              return {
                ...channel,
                ...userChannels[key],
              };
            });
        });
        resolve();
      });
    });
  }

  handleSamlLogin = () => {
    const { samlProvider } = this.state;
    firebase
      .auth()
      .signInWithPopup(samlProvider)
      .then((samlUser) => {
        samlUser.user
          .updateProfile({
            displayName: `${samlUser.additionalUserInfo.profile.firstName} ${samlUser.additionalUserInfo.profile.lastName}`,
            photoURL: samlUser.additionalUserInfo.profile.profilePic,
          })
          .then(() => {
            this.saveUser(samlUser).then(() => {
              this.saveOrg(samlUser).then(() => {
                this.saveAreaOfExpertise(samlUser)
                  .then(() => {
                    this.props.setUser(samlUser.user);
                  })
                  .catch((error) => {
                    console.error(error);
                  });
              });
            });
          })
          .catch((err) => {
            console.error(err);
            this.setState({
              errors: this.state.errors.concat(err),
              loading: false,
            });
          });

        samlUser.user
          .updateEmail(samlUser.additionalUserInfo.profile.email)
          .then(() => {
            console.log();
          })
          .catch((err) => {
            console.error('email update error', err);
          });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  handleUserReauth = () => {
    const { samlProvider } = this.state;
    const firebaseAuth = firebase.auth();
    if (firebaseAuth.currentUser) {
      firebase
        .auth()
        .currentUser.reauthenticateWithPopup(samlProvider)
        .then((result) => {
          return result.user.getIdTokenResult();
        })
        .then((idTokenResult) => {
          console.log('idTokenResult:', idTokenResult);
        })
        .catch((err) => {
          console.error(err);
          let { errors } = this.state;
          errors.push(err);
          this.setState({
            errors,
          });
        });
    } else {
      let { errors } = this.state;
      errors.push({ message: 'firebase current user does not exists' });
      this.setState({
        errors,
      });
      console.log('firebase current user does not exists');
    }
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  displayErrors = (errors) => {
    return errors.map((error, i) => <p key={i}>{error.message}</p>);
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.isFormValid(this.state)) {
      console.log(
        'email=%s, password=$s',
        this.state.email,
        this.state.password
      );
      this.setState({ errors: [], loading: true });
      firebase
        .auth()
        .signInWithEmailAndPassword(this.state.email, this.state.password)
        // .signInWithPopup(this.state.samlProvider)
        .then((signedInUser) => {
          console.log(signedInUser);
        })
        .catch((err) => {
          console.error(err);
          this.setState({
            errors: this.state.errors.concat(err),
            loading: false,
          });
        });
    }
  };

  isFormValid = ({ email, password }) => email && password;

  handleInputError = (errors, inputName) => {
    return errors.some((error) =>
      error.message.toLowerCase().includes(inputName)
    )
      ? 'error'
      : '';
  };

  render() {
    const { errors } = this.state;
    return (
      <Grid verticalAlign="middle" textAlign="center" className="app">
        <Grid.Column style={{ maxWidth: 400 }}>
          <Header as="h1" style={{ color: '#4A7BA4' }} textAlign="center">
            <Image size="small" src="favicon.png" />
            Login to Fleet Forum Chat
          </Header>

          {errors.length > 0 && (
            <Message error>
              <h3>Error</h3>
              {this.displayErrors(errors)}
            </Message>
          )}
          <Message>
            <Button
              color="green"
              style={{ backgroundColor: '#BEC887' }}
              fluid
              size="medium"
              onClick={this.handleSamlLogin}
            >
              Login
            </Button>
          </Message>
        </Grid.Column>
      </Grid>
    );
  }
}
const matchStateToProps = (state) => ({
  isLoading: state.user.isLoading,
});

export default connect(matchStateToProps, { setUser })(SSO);
