import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyAeahFKXWPx7YUo4FuDqGJKJCiwD_WKEsk',
  authDomain: 'ff-demo-chat-debug.firebaseapp.com',
  databaseURL: 'https://ff-demo-chat-debug.firebaseio.com',
  projectId: 'ff-demo-chat-debug',
  storageBucket: 'ff-demo-chat-debug.appspot.com',
  messagingSenderId: '382396608979',
  appId: '1:382396608979:web:0e613639c1da2dd50c3f26',
  measurementId: 'G-3ERQ23FKBQ',
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase;
