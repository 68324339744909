import React, { Component } from 'react';
import { Header, Segment, Input, Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
// import { setSearchUserIndex } from '../../actions';

class MessagesHeader extends Component {
  // handleSearchChange = (e) => {
  //   let value = e.target.value;
  // this.props.setSearchUserIndex(value)
  // };

  render() {
    // prettier-ignore
    const { channelName, numUniqueUsers, handleSearchChange, searchLoading, isPrivateChannel, handleStar, isChannelStarred } = this.props
    return (
      <Segment clearing>
        {/* Channel Title */}
        <Header fluid="true" as="h2" floated="left" style={{ marginBotton: 0 }}>
          <span>
            {channelName}
            {!isPrivateChannel && (
              <Icon
                onClick={handleStar}
                name={isChannelStarred ? 'star' : 'star outline'}
                color={isChannelStarred ? 'yellow' : 'black'}
              />
            )}
          </span>
          <Header.Subheader>{numUniqueUsers}</Header.Subheader>
        </Header>

        {/* Channel Search Input */}
        <Header floated="right">
          {/* <Input
            size="mini"
            icon="search"
            name="searchTerm"
            placeholder="Search Users"
            onChange={this.handleSearchChange}
          /><br/> */}
          <Input
            size="mini"
            icon="search"
            name="searchTerm"
            placeholder="Search Messages"
            onChange={handleSearchChange}
            loading={searchLoading}
          />
        </Header>
      </Segment>
    );
  }
}
const mapStateToProps = (state) => ({
  userSearchIndex: state.searchUser.userSearchIndex,
});

export default connect(mapStateToProps)(MessagesHeader);
