import React from 'react';
import {
  Segment,
  Accordion,
  Header,
  Icon,
  Image,
  List,
} from 'semantic-ui-react';
import firebase from '../../firebase';
import { connect } from 'react-redux';
import { setCurrentChannel, setPrivateChannel } from '../../actions';
// import AllOrganizations from '../MetaPanel/AllOrganizations'

class AreasOfExpertise extends React.Component {
  state = {
    user: this.props.currentUser,
    activeIndex: 0,
    channelsRef: firebase.database().ref('channels'),
    channels: [],
    myChannels: [],
  };

  UNSAFE_componentWillMount() {
    this.addListener();
  }

  setActiveIndex = (event, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;
    this.setState({ activeIndex: newIndex });
  };

  addListener = () => {
    const { channelsRef, user } = this.state;

    if (!user.info.channels) {
      return;
    }

    channelsRef.on('value', (snap) => {
      let val = snap.val();
      if (val) {
        let channels = Object.keys(val).map((key) => {
          return {
            ...val[key],
          };
        });
        const myChannelsKey = Object.keys(user.info.channels);
        let myChannels = channels.filter((channel) =>
          myChannelsKey.find((channelKey) => channel.id === channelKey)
        );
        this.setState({
          channels,
          myChannels,
        });
      }
    });
  };

  openChannelMessage = (channel) => {
    this.props.setCurrentChannel(channel);
    this.props.setPrivateChannel(false);
    window.scrollTo(0, 0);
  };

  displayAllChannels = (channels) =>
    channels.map(
      (channel, i) =>
        !channel.public && (
          <List.Item
            key={i}
            onClick={() => this.openChannelMessage(channel)}
            className="channels-panel-item"
            disabled={channel.state === false}
          >
            {channel.logo.indexOf('http') > -1 ? (
              <Image avatar src={channel.logo} />
            ) : (
              <Icon name={'road'} />
            )}
            <List.Content>
              <List.Header>{channel.name}</List.Header>
              <List.Description>{channel.details}</List.Description>
            </List.Content>
          </List.Item>
        )
    );

  displayPublicChannels = (channels) =>
    channels.map(
      (channel, i) =>
        channel.isCustom === true && (
          <List.Item
            key={i}
            onClick={() => this.openChannelMessage(channel)}
            className="channels-panel-item"
            disabled={channel.state === false}
          >
            {channel.logo.indexOf('http') > -1 ? (
              <Image avatar src={channel.logo} />
            ) : (
              <Icon name={'road'} />
            )}
            <List.Content>
              <List.Header>{channel.name}</List.Header>
              <List.Description>{channel.details}</List.Description>
            </List.Content>
          </List.Item>
        )
    );

  render() {
    const { activeIndex, channels } = this.state;
    return (
      <Segment loading={!channels}>
        <Header as="h3" attached="top">
          Areas Of Expertise
        </Header>
        <Accordion styled attached="true">
          <Accordion.Title
            active={activeIndex === 1}
            index={1}
            onClick={this.setActiveIndex}
          >
            <Icon name="dropdown" />
            <Icon circular color="green" name="road" />
            All Areas Of Expertise
          </Accordion.Title>
          <Accordion.Content
            active={activeIndex === 1}
            className="channels-panel"
          >
            <List>
              {channels.length > 0 && this.displayAllChannels(channels)}
            </List>
          </Accordion.Content>
          <Accordion.Title
            active={activeIndex === 2}
            index={2}
            onClick={this.setActiveIndex}
          >
            <Icon name="dropdown" />
            <Icon circular color="green" name="road" />
            Private Channels
          </Accordion.Title>
          <Accordion.Content
            active={activeIndex === 2}
            className="channels-panel"
          >
            <List>{channels && this.displayPublicChannels(channels)}</List>
          </Accordion.Content>
        </Accordion>
      </Segment>
    );
  }
}

export default connect(null, { setCurrentChannel, setPrivateChannel })(
  AreasOfExpertise
);
