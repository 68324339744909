module.exports = {
  // serverUrl: 'https://backend.chat.fleetforum.org',
  // frontendUrl: 'https://chat.fleetforum.org',

  // serverUrl: 'https://backend.chat.myogenic.solutions',
  // frontendUrl: 'https://chat.myogenic.solutions',

  serverUrl: 'http://localhost:4200',
  frontendUrl: 'http://localhost:3000',
};
