import React from 'react';
import { connect } from 'react-redux';
import {
  Header,
  Table,
  Button,
  Icon,
  Image,
  Form,
  Input,
  Dropdown,
} from 'semantic-ui-react';
import firebase from '../../firebase';
import { setShowAreasOfExpertiseModal } from '../../actions/index';
import ConfirmModal from '../common/confirmModal';
import AddMemberToArea from './pages/AddMemberToArea';

const tagOptions = [
  {
    key: 'All',
    text: 'All',
    value: 'all',
    label: { color: 'orange', empty: true, circular: true },
  },
  {
    key: 'Enabled',
    text: 'Enabled',
    value: 'enabled',
    label: { color: 'green', empty: true, circular: true },
  },
  {
    key: 'disabled',
    text: 'Disabled',
    value: 'disabled',
    label: { color: 'red', empty: true, circular: true },
  },
];
class CustomAreasOfExpertiseTable extends React.Component {
  state = {};
  constructor(props) {
    super(props);

    this.state = {
      areasOfExpertise: [],
      areasOfExpertiseRef: firebase.database().ref('channels'),
      storageRef: firebase.storage().ref(),
      database: firebase.database(),
      modalOpen: false,
      selectedData: null,
      key: '',
      areasNameIndex: '',
      areasStateIndex: '',
      addMembers: false,
      selectedArea: {},
    };
  }

  UNSAFE_componentWillMount() {
    this.getareasOfExpertise();
    this.addListener();
    this.deleteListener();
  }

  componentWillUnmount() {
    // this.state.areasOfExpertiseRef.off();
  }

  getareasOfExpertise() {
    let { areasOfExpertiseRef } = this.state;
    areasOfExpertiseRef.on('value', (snap) => {
      this.setareasOfExpertise(snap.val());
    });
  }

  setareasOfExpertise = (areasOfExpertise) => {
    if (areasOfExpertise === undefined) {
      return;
    }
    let tempData = Object.keys(areasOfExpertise).map((key) => {
      return {
        ...areasOfExpertise[key],
      };
    });

    tempData = tempData.filter((area) => area.isCustom);

    this.setState({
      areasOfExpertise: tempData,
    });
    this.addListener();
    this.deleteListener();
  };

  addDataToState = (newArea) => {
    if (newArea.isCustom) {
      let areasOfExpertise = this.state.areasOfExpertise
        .slice()
        .filter((org) => {
          return org.id !== newArea.id;
        });
      areasOfExpertise.push(newArea);
      this.setState({
        areasOfExpertise,
      });
    }
  };

  removeAreasOfExpertise = (deletdOrg) => {
    let areasOfExpertise = this.state.areasOfExpertise.slice().filter((org) => {
      return org.id !== deletdOrg.id;
    });
    this.setState({
      areasOfExpertise,
    });
  };

  addListener = () => {
    let { areasOfExpertiseRef } = this.state;
    areasOfExpertiseRef.on('child_changed', (snap) => {
      if (snap.val().isCustom) {
        this.addDataToState(snap.val());
      }
    });
  };

  deleteListener = () => {
    let { areasOfExpertiseRef } = this.state;
    areasOfExpertiseRef.on('child_removed', (snap) => {
      let deletedOrg = snap.val();
      this.removeAreasOfExpertise(deletedOrg);
    });
  };

  deleteAreasOfExpertise = (key) => {
    this.setState({
      key,
    });
    this.setOpen(true);
  };

  editAreaOfExpertise = (areaOfExp) => {
    this.props.setShowAreasOfExpertiseModal(areaOfExp);
  };

  setOpen = (value) => {
    this.setState({
      modalOpen: value,
    });
  };

  onConfirm = (value) => {
    if (value) {
      let { database, storageRef, key } = this.state;
      if (key) {
        database.ref('channels/' + key).remove();
        storageRef
          .child(`channels/` + key)
          .delete()
          .then(() => {
            console.log(' delete success');
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        console.error(key);
      }
    }
  };

  setPublic = (channel) => {
    const { areasOfExpertiseRef } = this.state;
    areasOfExpertiseRef
      .child(`${channel.id}/public`)
      .set(!channel.public)
      .then(() => {
        console.log('successfully set to pubilc');
      })
      .catch((err) => {
        console.error(err);
      });
  };

  addMembers = (area) => {

    console.log('area', area);
    this.setState({
      addMembers: true,
      selectedArea: area,
    });
  };

  displayData = (areasOfExpertise) => {
    let keys = Object.keys(areasOfExpertise);
    return keys.map((key, index) => (
      // bg-c-lightblue is for custom groups
      <Table.Row key={index}>
        <Table.Cell>
          <Header as="h5" textAlign="center">
            {index + 1}
          </Header>
        </Table.Cell>
        <Table.Cell singleLine>{areasOfExpertise[key].name}</Table.Cell>
        <Table.Cell textAlign="center">
          <Icon
            name="circle"
            size={'large'}
            color={areasOfExpertise[key].state === true ? 'green' : 'red'}
          />
        </Table.Cell>
        <Table.Cell>
          <Image
            src={areasOfExpertise[key].logo}
            spaced="right"
            className="org-logo"
            size={'mini'}
          />
        </Table.Cell>
        <Table.Cell textAlign={'center'} width={'2'}>
          {areasOfExpertise[key].createdBy &&
            areasOfExpertise[key].createdBy.name}
        </Table.Cell>
        <Table.Cell>{areasOfExpertise[key].details}</Table.Cell>
        {/* public */}
        <Table.Cell textAlign="center">
          {areasOfExpertise[key].public ? (
            <Button
              color="green"
              className="circle"
              icon
              onClick={() => this.setPublic(areasOfExpertise[key])}
            ></Button>
          ) : (
            <Button
              color="red"
              className="circle"
              onClick={() => this.setPublic(areasOfExpertise[key])}
              icon
            ></Button>
          )}
        </Table.Cell>
        <Table.Cell width="1" textAlign="center">
          <Button
            positive
            size="mini"
            onClick={() => {
              this.addMembers(areasOfExpertise[key]);
            }}
          >
            Add Members
          </Button>
        </Table.Cell>
        {/* action */}
        <Table.Cell width={'1'}>
          <Button.Group>
            <Button
              positive
              onClick={() => {
                this.editAreaOfExpertise(areasOfExpertise[key]);
              }}
            >
              Edit
            </Button>
            <Button.Or />
            <Button
              color="red"
              onClick={() =>
                this.deleteAreasOfExpertise(areasOfExpertise[key].id)
              }
            >
              Delete
            </Button>
          </Button.Group>
        </Table.Cell>
      </Table.Row>
    ));
  };
  onSearchHandleChange = (e, data) => {
    this.setState({
      [e.target.name]: data.value,
    });
  };

  closeAddmembersModal = () => {
    this.setState({
      addMembers: false,
    });
  };

  render() {
    let { areasOfExpertise, areasNameIndex, areasStateIndex } = this.state;
    let regexp = new RegExp(areasNameIndex, 'i');
    areasOfExpertise = areasOfExpertise.filter(
      (area) =>
        area.name.search(regexp) > -1 ||
        area.createdBy.name.search(regexp) > -1 ||
        area.details.search(regexp) > -1
    );
    switch (areasStateIndex) {
      case 'disabled':
        areasOfExpertise = areasOfExpertise.filter(
          (area) => area.state === false
        );
        break;
      case 'enabled':
        areasOfExpertise = areasOfExpertise.filter((area) => {
          return area.state !== undefined || area.state !== false;
        });
        break;
      default:
        break;
    }

    return (
      <div style={{ minWidth: '440px !important' }}>
        <Header as="h2" icon textAlign="center">
          <Icon name="th" circular />
          <Header.Content>Private Channels</Header.Content>
        </Header>
        {/* search */}
        <Form>
          <Form.Group className="float-right">
            <Input
              icon={{ name: 'search', circular: true, link: true }}
              placeholder="Search Group name or others..."
              onChange={(e, d) => {
                e.target.name = 'areasNameIndex';
                this.onSearchHandleChange(e, d);
              }}
            />
            <Dropdown
              text="State"
              icon="filter"
              floating
              labeled
              button
              className="icon"
            >
              <Dropdown.Menu>
                <Input icon="search" iconPosition="left" className="search" />
                <Dropdown.Divider />
                <Dropdown.Header icon="users" content="State" />
                <Dropdown.Menu scrolling>
                  {tagOptions.map((option) => (
                    <Dropdown.Item
                      key={option.value}
                      {...option}
                      onClick={(e, d) => {
                        e.target.name = 'areasStateIndex';
                        this.onSearchHandleChange(e, d);
                      }}
                    />
                  ))}
                </Dropdown.Menu>
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
        </Form>
        {/* search */}
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell singleLine>No</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">State</Table.HeaderCell>
              <Table.HeaderCell>Logo</Table.HeaderCell>
              <Table.HeaderCell>Created By</Table.HeaderCell>
              <Table.HeaderCell>Detail</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Public</Table.HeaderCell>
              <Table.HeaderCell textAlign="center" width="1">
                Add Members
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Edit</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {areasOfExpertise.length > 0 && this.displayData(areasOfExpertise)}
          </Table.Body>
        </Table>
        <ConfirmModal
          open={this.state.modalOpen}
          setOpen={this.setOpen}
          onConfirm={this.onConfirm}
          header={'Delete Areas Of Expertise'}
          content={'Are you really want to delete this Area of Expertise?'}
        />
        {this.state.addMembers && (
          <AddMemberToArea
            open={this.state.addMembers}
            onClose={this.closeAddmembersModal}
            selectedArea={this.state.selectedArea}
          />
        )}
      </div>
    );
  }
}

const actions = {
  setShowAreasOfExpertiseModal,
};

export default connect(null, actions)(CustomAreasOfExpertiseTable);
