import React, { Component } from 'react';
import {
  Menu,
  Icon,
  Modal,
  Form,
  Input,
  Button,
  Grid,
  Image,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import AvatarEditor from 'react-avatar-editor';
import firebase from '../../firebase';
import { PRIVATE_CHANNELS } from './SidePanel/type';
import { buildChannelUrlName } from '../../functions';

import {
  setCurrentChannel,
  setPrivateChannel,
  setActiveItemOfAdminSideBar,
} from '../../actions';

class PrivateChannels extends Component {
  state = {
    user: this.props.currentUser,
    privateChannelsRef: firebase.database().ref('channels'),
    croppedImage: '',
    uploadedCroppedImage: '',
    previewImage: '',
    modal: false,
    name: '',
    details: '',
    storageRef: firebase.storage().ref(),
    blob: '',
    state: false,
  };

  openModal = () => {
    this.setState({ modal: true });
  };

  displayPrivateChannels = () => {
    this.props.setActiveItemOfAdminSideBar(PRIVATE_CHANNELS);
  };
  handleSubmit = (event) => {
    event.preventDefault();
    if (this.isFormValid(this.state)) {
      this.addPrivateChannels();
    }
  };
  addPrivateChannels = () => {
    const { privateChannelsRef, name, details, state, user } = this.state;
    const key = privateChannelsRef.push().key;
    const newPrivateChannel = {
      id: key,
      name: name,
      details: details,
      state: state,
      refUrl: buildChannelUrlName(name),
      logo: 'Logo',
      createdBy: {
        name: user.displayName,
        avatar: user.photoURL,
      },
      isCustom: true,
    };
    privateChannelsRef
      .child(key)
      .update(newPrivateChannel)
      .then(() => {
        this.setState({ name: '', details: '' });
        this.uploadCroppedImage(key);
        this.closeModal();
        console.log('channel added');
      })
      .catch((err) => {
        console.error('channel added error', err);
      });
  };
  closeModal = () => {
    this.setState({ modal: false });
  };

  handleChange = (event) => {
    if (event.target.name === 'logo') {
      const file = event.target.files[0];
      const reader = new FileReader();

      if (file && file.type.match('image.*')) {
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          this.setState({ previewImage: reader.result });
        };
      }
    } else if (event.target.name === 'state') {
      this.setState({
        state: !this.state.state,
      });
    } else {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
  };

  isFormValid = ({ name, details }) => name && details;

  uploadCroppedImage = (id) => {
    const { storageRef, blob, metadata } = this.state;

    storageRef
      .child(`channels/` + id)
      .put(blob, metadata)
      .then((snap) => {
        snap.ref.getDownloadURL().then((downloadUrl) => {
          this.setState({ uploadedCroppedImage: downloadUrl });
          this.saveLogoUrl(id, downloadUrl);
        });
      });
    this.closeModal();
  };

  saveLogoUrl = (key, downloadUrl) => {
    const { privateChannelsRef } = this.state;

    privateChannelsRef
      .child(key)
      .update({
        logo: downloadUrl,
      })
      .then(() => {
        console.log('save image url success');
      })
      .catch((error) => {
        console.error(error);
      });
  };

  handleCropImage = async () => {
    if (this.AvatarEditor) {
      this.AvatarEditor.getImageScaledToCanvas().toBlob((blob) => {
        let imageUrl = URL.createObjectURL(blob);
        this.setState({
          croppedImage: imageUrl,
          blob,
        });
      });
    }
  };

  render() {
    const { modal, previewImage, croppedImage } = this.state;
    const active = this.props.activedSideItemName === PRIVATE_CHANNELS;
    return (
      <Grid>
        <Menu.Menu className="menu">
          <Menu.Item
            onClick={this.displayPrivateChannels}
            style={{ opacity: 0.7 }}
            active={active}
          >
            <span>
              <Icon name="th" />
              <p
                style={{
                  display: 'table-footer-group',
                  paddingRight: '1.1rem',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                <span>Private Channels</span>
              </p>
            </span>{' '}
            {/* ({channels.length}){' '} */}
            <Icon
              name="add"
              onClick={this.openModal}
              style={{ cursor: 'pointer' }}
              className="cutom_areas_title"
            />
          </Menu.Item>
        </Menu.Menu>
        {/* add channel */}
        <Modal basic open={modal} onClose={this.closeModal}>
          <Modal.Header>Add Private Channel</Modal.Header>
          <Modal.Content>
            <Form onSubmit={this.handleSubmit}>
              <Form.Field>
                <Input
                  fluid
                  label="Title"
                  name="name"
                  onChange={this.handleChange}
                  style={{ marginBottom: '1em' }}
                />
              </Form.Field>
              <Form.Field>
                <Input
                  fluid
                  label="Private Channel Details"
                  name="details"
                  onChange={this.handleChange}
                  style={{ marginBottom: '1em' }}
                />
                <Input
                  fluid
                  label="Logo"
                  name="logo"
                  onChange={this.handleChange}
                  type="file"
                />
                <div style={{ textAlign: 'right', color: 'white' }}>
                  <label style={{ color: 'white', paddingLeft: '10' }}>
                    {' '}
                    State{' '}
                  </label>
                  <Form.Radio
                    toggle
                    name="state"
                    onChange={(e) => {
                      e.target.name = 'state';
                      this.handleChange(e);
                    }}
                  />
                </div>
                <Grid centered stackable columns={2}>
                  <Grid.Row centered>
                    <Grid.Column className="ui center aligned grid">
                      {previewImage && (
                        <AvatarEditor
                          image={previewImage}
                          ref={(node) => (this.AvatarEditor = node)}
                          // width={120}
                          // height={120}
                          border={50}
                          scale={1.2}
                        />
                      )}
                    </Grid.Column>
                    <Grid.Column>
                      {croppedImage && (
                        <Image
                          style={{ margin: '3.5em auto' }}
                          width={100}
                          height={100}
                          src={croppedImage}
                        />
                      )}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form.Field>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            {/* {croppedImage && (
              
            )} */}
            <Button
              color="green"
              inverted
              // onClick={this.uploadCroppedImage}
              onClick={async (e) => {
                this.handleSubmit(e);
              }}
            >
              <Icon name="save" /> Save
            </Button>
            <Button color="green" inverted onClick={this.handleCropImage}>
              <Icon name="checkmark" /> Preview Image
            </Button>
            <Button color="red" inverted onClick={this.closeModal}>
              <Icon name="remove" /> Cancel
            </Button>
          </Modal.Actions>
        </Modal>
      </Grid>
    );
  }
}

const actions = {
  setCurrentChannel,
  setPrivateChannel,
  setActiveItemOfAdminSideBar,
};

export default connect(null, actions)(PrivateChannels);
