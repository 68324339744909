import React from 'react'
import { Progress } from 'semantic-ui-react'

const ProgressBar = ({ uploadState, perccentUploaded }) =>
  uploadState && (
    <Progress
      className="progress__bar"
      percent={perccentUploaded}
      progress
      indicating
      size="medium"
      inverted
    />
  )

export default ProgressBar
