import React, { Component } from 'react';
import moment from 'moment';
import { Comment, Image, Button, Icon, Input } from 'semantic-ui-react';
import firebase from '../../firebase';
import Excel from '../../assets/imgs/file_types/excel.png';
import PDF from '../../assets/imgs/file_types/pdf.png';
import PowerPoint from '../../assets/imgs/file_types/ppt.png';
import Word from '../../assets/imgs/file_types/word.png';
import axios from 'axios';
import { serverUrl } from '../../service/config';

const EditAndDeleteMessage = (props) => {
  if (props.isOwnMessage(props.message, props.user)) {
    if (props.typeState === 'text') {
      return (
        <div>
          <Comment.Content className={'pl-1 pr-1 ' + props.className}>
            <Icon
              name={!props.editState ? 'edit' : 'delete'}
              className="pointer"
              onClick={() => {
                props.setEditState(!props.editState ? 'edit' : '');
              }}
            />
            {props.editState ? (
              <Icon
                name="check"
                className="pointer"
                onClick={() => {
                  props.SaveMessage(props.message.id);
                }}
              />
            ) : (
              <Icon
                name="delete"
                className="pointer"
                onClick={() => {
                  props.deleteMessage(props.message.id);
                }}
              />
            )}
          </Comment.Content>
        </div>
      );
    } else if (props.typeState === 'file') {
      return (
        <Comment.Content className={'pl-1 pr-1 ' + props.className}>
          <Icon
            name="delete"
            className="pointer"
            onClick={() => {
              props.deleteFile(props.message);
            }}
          />
        </Comment.Content>
      );
    }
  } else {
    return <div></div>;
  }
};

const MessageDisplay = (props) => {
  var FileIcon = '';
  var FileName = '';
  if (props.message.file_name && props.message.file_name.length <= 15) {
    FileName = props.message.file_name;
  } else if (props.message.file_name && props.message.file_name.length > 15) {
    FileName = props.message.file_name.slice(0, 15) + '...';
  }

  if (props.message.file_type === 'ppt' || props.message.file_type === 'pptx') {
    FileIcon = PowerPoint;
  }

  if (props.message.file_type === 'xls' || props.message.file_type === 'xlsx') {
    FileIcon = Excel;
  }

  if (props.message.file_type === 'doc' || props.message.file_type === 'docx') {
    FileIcon = Word;
  }

  if (props.message.file_type === 'pdf') {
    FileIcon = PDF;
  }
  if (
    props.message.hasOwnProperty('image') &&
    !props.message.hasOwnProperty('content') &&
    !props.message.hasOwnProperty('document')
  ) {
    return <Image src={props.message.image} className="message__image pl-2" />;
  } else if (
    props.message.hasOwnProperty('document') &&
    !props.message.hasOwnProperty('content') &&
    !props.message.hasOwnProperty('image')
  ) {
    return (
      <div style={{ display: 'table', textAlign: 'center' }}>
        <br />
        <Comment.Content>
          <img src={FileIcon} width="30px" height="30px"></img>
        </Comment.Content>
        <Comment.Content>
          <Button className="pl-2 fileName">
            <a href={props.message.document}>
              <p>{FileName ? FileName : 'Attached File'}</p>
            </a>
          </Button>
        </Comment.Content>
      </div>
    );
  } else {
    return (
      <Comment.Content>
        {!props.message.hasOwnProperty('isAlert') && (
          <Input
            style={{ display: 'flex' }}
            className={props.className}
            disabled={!props.editState}
            value={props.messageContent}
            onChange={(e) => {
              props.editMessage(e);
            }}
          ></Input>
        )}
      </Comment.Content>
    );
  }
};

class Message extends Component {
  state = {
    editState: false,
    showIcons: false,
    showMessageId: '',
    editableMessageSate: false,
    messageContent: '',
    storageRef: firebase.storage().ref(),
    user_avatar: '',
  };

  componentDidMount() {
    this.setState({
      messageContent: this.props.message.content,
      user_avatar:
        'https://knowledge.fleetforum.org/public//avatars//16x16_019191756ff1a2b679270d75a5436688.jpg',
    });
  }

  isOwnMessage = (message, user) => {
    return message.user.id === user.uid ? true : false;
  };

  isText = (message) => {
    if (
      !message.hasOwnProperty('image') &&
      message.hasOwnProperty('content') &&
      !message.hasOwnProperty('document') &&
      !message.hasOwnProperty('isAlert')
    ) {
      return true;
    }

    return false;
  };

  timeFromNow = (timestamp) => moment(timestamp).fromNow();

  setEditState = (state) => {
    if (state === 'edit') {
      this.setState({
        editState: true,
        editableMessageSate: true,
      });
    } else {
      this.setState({
        editState: false,
        editableMessageSate: false,
      });
    }
  };

  setShowState = (message) => {
    this.setState({
      showMessageId: message.id,
      showIcons: true,
    });
  };

  setHideState = (message) => {
    this.setState({
      showMessageId: message.id,
      showIcons: false,
    });
  };

  EditMessage = (e) => {
    this.setState({
      messageContent: e.target.value,
    });
  };

  SaveMessage = (message_id) => {
    var timestamp = firebase.database.ServerValue.TIMESTAMP;
    const { getMessageRef } = this.props;
    const this_ = this;
    const ref = firebase
      .database()
      .ref(
        '/' +
          getMessageRef() +
          '/' +
          this.props.currentChannel.id +
          '/' +
          message_id
      );
    ref
      .update({
        content: this.state.messageContent,
        timestamp: timestamp,
      })
      .then(() => {
        console.log('Success!');
        this_.props.addListeners(this_.props.currentChannel.id);
      })
      .catch(function (error) {
        console.error('Error removing data: ', error);
      });

    this.setState({
      editState: false,
      editableMessageSate: false,
    });
  };

  DeleteMessage = (message_id) => {
    const { getMessageRef } = this.props;
    const ref = firebase
      .database()
      .ref(`${getMessageRef()}/${this.props.currentChannel.id}`);
    const this_ = this;
    ref
      .child(`${message_id}`)
      .remove()
      .then(function () {
        console.log('Success!');
        this_.props.addListeners(this_.props.currentChannel.id);
      })
      .catch(function (error) {
        console.error('Error removing data: ', error);
      });
  };

  getPath = () => {
    if (this.props.isPrivateChannel) {
      return `chat/private/${this.props.currentChannel.id}`;
    } else {
      return 'chat/public';
    }
  };

  deleteFile = (deleteFile) => {
    console.log('delete file id', deleteFile.id, 'name', deleteFile.fileName);
    const { getMessageRef } = this.props;
    const ref = firebase
      .database()
      .ref(`${getMessageRef()}/${this.props.currentChannel.id}`);
    const this_ = this;
    const deleteFilePath = `${this.getPath()}/${deleteFile.fileName}.${
      deleteFile.file_type
    }`;

    this.state.storageRef
      .child(deleteFilePath)
      .delete()
      .then(() => {
        console.log('Successfully Delete!');
      })
      .catch(function (error) {
        console.error('Error Deleting File', error);
      });

    ref
      .child(`${deleteFile.id}`)
      .remove()
      .then(function () {
        console.log('Success!');
        this_.props.addListeners(this_.props.currentChannel.id);
      })
      .catch(function (error) {
        console.error('Error removing data: ', error);
      });
  };

  render() {
    const { message, user, currentChannel } = this.props;
    const userRole = user.role;
    const { messageContent } = this.state;

    return (
      <Comment
        onMouseOver={() => {
          this.setShowState(message);
        }}
        onMouseOut={() => {
          this.setHideState(message);
        }}
      >
        <div align="right">
          {this.isText(message) ? (
            <EditAndDeleteMessage
              className={
                this.state.showIcons && this.state.showMessageId === message.id
                  ? 'fade-in'
                  : 'hide'
              }
              message={message}
              user={user}
              currentChannel={currentChannel}
              isOwnMessage={this.isOwnMessage}
              deleteMessage={this.DeleteMessage}
              setEditState={this.setEditState}
              SaveMessage={this.SaveMessage}
              editState={this.state.editState}
              isEdit={this.state.isEdit}
              editableMessageSate={this.state.editableMessageSate}
              userRole={userRole}
              typeState="text"
            />
          ) : (
            <EditAndDeleteMessage
              className={
                this.state.showIcons && this.state.showMessageId === message.id
                  ? 'fade-in'
                  : 'hide'
              }
              message={message}
              user={user}
              currentChannel={currentChannel}
              isOwnMessage={this.isOwnMessage}
              deleteFile={this.deleteFile}
              userRole={userRole}
              typeState="file"
            />
          )}
        </div>
        <Comment.Avatar src={message.user.avatar} />
        <Comment.Content
          className={this.isOwnMessage(message, user) ? 'message__self' : ''}
        >
          <Comment.Author as="a"> {message.user.name} </Comment.Author>
          <Comment.Metadata>
            {' '}
            {this.timeFromNow(message.timestamp)}{' '}
          </Comment.Metadata>
          <MessageDisplay
            message={message}
            className={!this.state.editableMessageSate ? 'none-style' : ''}
            editState={this.state.editState}
            messageContent={messageContent}
            editMessage={this.EditMessage}
            setState={this.setState}
          />
        </Comment.Content>
      </Comment>
    );
  }
}

export default Message;
