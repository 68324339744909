import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react';
import { connect } from 'react-redux';

import AdminSidePanel from './SidePanel/AdminSidePanel';
import AdminPanelGuard from '../Admin/AdminPanelGuard';

import OrganizationTable from './OrganizationTable';
import AreasOfExpertiseTable from './AreasOfExpertiseTable';
import CustomAreasOfExpertiseTable from './CustomAreasOfExpertiseTable';
import UsersTable from './UsersTable';
import GroupUserManagementTable from './GroupUserManagementTable';

import EditOrganizationModal from './EditOrganizationModal';
import EditAreasOfExpertiseModal from './EditAreasOfExpertiseModal';
import EditUserModal from './EditUserModal';
import GroupUserEditModal from './GroupUserEditModal';

import {
  ORGANIZATION,
  AREASOFEXPERTISE,
  USERS,
  USER_GROUP_MANAGEMENT,
  PRIVATE_CHANNELS,
} from './SidePanel/type';

import { isAdmin } from '../../passportOrganizations';
import '../App.css';
import { setActiveItemOfAdminSideBar } from '../../actions';
import firebase from '../../firebase';

class AdminIndex extends Component {
  UNSAFE_componentWillMount() {
    if (!isAdmin(this.props.currentUser)) {
      this.props.history.push('/');
    }
  }

  componentWillUnmount() {
    this.props.setActiveItemOfAdminSideBar('');
    // firebase.database().ref('users').off();
    // firebase.database().ref('organisations').off();
    // firebase.database().ref('channels').off();
  }

  render() {
    const {
      currentUser,
      primaryColor,
      activedSideItemName,
      isOpenModal,
      selectedOrg,
      setCloseModal,
      isOpenAreaOfExpertiseModal,
      selectedAreaOfExpertise,
      isOpenUserEditModal,
      selectedUser,
      isOpenGroupUserEditModal,
      selectedGroupUser,
    } = this.props;

    return (
      <>
        <AdminSidePanel
          currentUser={currentUser}
          primaryColor={primaryColor}
          activedSideItemName={activedSideItemName}
        ></AdminSidePanel>
        {activedSideItemName === ORGANIZATION && (
          <Grid.Column width={12} style={{ marginLeft: 250 }}>
            <OrganizationTable />
          </Grid.Column>
        )}
        {activedSideItemName === AREASOFEXPERTISE && (
          <Grid.Column width={12} style={{ marginLeft: 250 }}>
            <AreasOfExpertiseTable />
          </Grid.Column>
        )}
        {activedSideItemName === USERS && (
          <Grid.Column width={12} style={{ marginLeft: 250 }}>
            <UsersTable currentUser={currentUser} />
          </Grid.Column>
        )}
        {activedSideItemName === USER_GROUP_MANAGEMENT && (
          <Grid.Column width={12} style={{ marginLeft: 250 }}>
            <GroupUserManagementTable currentUser={currentUser} />
          </Grid.Column>
        )}
        {activedSideItemName === '' && (
          <Grid.Column width={12} style={{ marginLeft: 250 }}>
            <AdminPanelGuard></AdminPanelGuard>
          </Grid.Column>
        )}
        {/* Custom groups */}
        {activedSideItemName === PRIVATE_CHANNELS && (
          <Grid.Column width={12} style={{ marginLeft: 250 }}>
            <CustomAreasOfExpertiseTable />
          </Grid.Column>
        )}
        {isOpenModal && (
          <EditOrganizationModal
            currentUser={currentUser}
            isOpenModal={isOpenModal}
            selectedOrg={selectedOrg}
            setCloseModal={setCloseModal}
          />
        )}
        {isOpenAreaOfExpertiseModal && (
          <EditAreasOfExpertiseModal
            currentUser={currentUser}
            isOpenAreaOfExpertiseModal={isOpenAreaOfExpertiseModal}
            selectedAreaOfExpertise={selectedAreaOfExpertise}
          />
        )}
        {isOpenUserEditModal && (
          <EditUserModal
            currentUser={currentUser}
            isOpenUserEditModal={isOpenUserEditModal}
            selectedUser={selectedUser}
          />
        )}
        {isOpenGroupUserEditModal && (
          <GroupUserEditModal
            currentUser={currentUser}
            isOpenGroupUserEditModal={isOpenGroupUserEditModal}
            selectedGroupUser={selectedGroupUser}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  currentChannel: state.channel.currentChannel,
  isPrivateChannel: state.channel.isPrivateChannel,
  userPosts: state.channel.userPosts,
  primaryColor: state.colors.primaryColor,
  secondaryColor: state.colors.secondaryColor,
  activedSideItemName: state.adminSidebar.activedSideItemName,
  isOpenModal: state.organizationEditModal.isOpenModal,
  selectedOrg: state.organizationEditModal.selectedOrg,
  setCloseModal: state.organizationEditModal.setCloseModal,
  isOpenAreaOfExpertiseModal:
    state.AreaOfExpertiseEditModal.isOpenAreaOfExpertiseModal,
  selectedAreaOfExpertise:
    state.AreaOfExpertiseEditModal.selectedAreaOfExpertise,
  isOpenUserEditModal: state.userEditModal.isOpenUserEditModal,
  selectedUser: state.userEditModal.selectedUser,
  isOpenGroupUserEditModal: state.groupUserEditModal.isOpenGroupUserEditModal,
  selectedGroupUser: state.groupUserEditModal.selectedGroupUser,
});

export default connect(mapStateToProps, {
  setActiveItemOfAdminSideBar,
})(AdminIndex);
