import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
} from 'react-router-dom';
import { createStore } from 'redux';
import { Provider, connect } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import axios from 'axios';
import { serverUrl } from './service/config';

import 'semantic-ui-css/semantic.min.css';

import App from './components/App';
import Login from './components/Auth/login';
import Register from './components/Auth/register';
import Admin from './components/Admin/Index';
import SSO from './components/Auth/sso';
import Spinner from './spinner';
import registerServiceWorker from './registerServiceWorker';
import firebase from './firebase';
import rootReducer from './reducers';
import { setUser, clearUser } from './actions';
import { signRole } from './passportOrganizations';

const store = createStore(rootReducer, composeWithDevTools());

class Root extends React.Component {
  usersRef = firebase.database().ref('users');
  orgsRef = firebase.database().ref('organisations');

  state = {
    user: this.props.currentUser,
  };

  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      this.checkUser(user);
    });
  }

  componentWillUnmount() {
    firebase.database().goOffline();
  }

  getAddtionalInfoAndRedirect = (user) => {
    this.usersRef.child(user.uid).once('value', (snap) => {
      if (snap.val()) {
        user.info = snap.val();
        if (user.info.organisation) {
          this.orgsRef.child(user.info.organisation).once('value', (snap) => {
            if (!snap.val()) {
              user.info.organisationInfo = {};
              user.info.organisationInfo.state = false;
            } else {
              user.info.organisationInfo = snap.val();
              this.setUserAndRedirect(user);
            }
          });
        }
      }
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.currentUser) {
      if (
        nextProps.currentUser &&
        !Object.is(this.state.user, nextProps.currentUser)
      ) {
        this.setState({
          user: nextProps.currentUser,
        });
        this.getAddtionalInfoAndRedirect(nextProps.currentUser);
      }
    }
  }

  setUserAndRedirect = (user) => {
    user = signRole(user);
    this.props.setUser(user);
    if (this.props.location.pathname === '/sso') {
      this.props.history.push('/');
    }
  };

  checkUser = (user) => {
    if (user) {
      this.getAddtionalInfoAndRedirect(user);
    } else {
      this.props.history.push('/sso'); // login
      this.props.clearUser();
    }
  };

  render() {
    return this.props.isLoading ? (
      <Spinner />
    ) : (
      <Switch>
        <Route exact path="/" component={App} />
        <Route path="/login" component={Login} />
        <Route path="/sso" component={SSO} />
        <Route path="/register" component={Register} />
        <Route path="/admin" component={Admin} />
        {/* <Route component={App} /> */}
      </Switch>
    );
  }
}

const matchStateToProps = (state) => ({
  isLoading: state.user.isLoading,
  currentUser: state.user.currentUser,
});

const RootWithAuth = withRouter(
  connect(matchStateToProps, { setUser, clearUser })(Root)
);

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <RootWithAuth />
    </Router>
  </Provider>,
  document.getElementById('root')
);
registerServiceWorker();
