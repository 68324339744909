import React, { Component } from "react";
import {
    Icon,
    Modal,
    Form,
    Button,
    Dropdown
} from 'semantic-ui-react';
import firebase from '../../firebase';

class AddGroupUserModal  extends Component {
    state = {
        usersRef: firebase.database().ref('users'),
        orgRef: firebase.database().ref('organisations'),
        users: [],
        orgs: [],
        selectedUser: null,
        selectedOrg: null,
    };

    UNSAFE_componentWillMount() {
        this.getUsers();
        this.getOrgs();
    }

    getUsers = () => {
        const { usersRef } = this.state;
    
        usersRef.on("value", (snap) => {
            if(snap.val())
                this.setUsersToState(snap.val())
        })
    }

    getOrgs = () => {
        const { orgRef } = this.state;
        orgRef.on("value", (snap) => {
            if(snap.val()) {
                this.setOrgsToState(snap.val())
            }
        })
    }

    setUsersToState = (obj) => {
        let users = Object.keys(obj).map((key) => {
          return {
            key,
            ...obj[key]
          }
        });
        this.setState({
            users,
            filteredUsers: users.slice()
        })
    }

    setOrgsToState = (obj) => {
        let orgs = Object.keys(obj).map((key) => {
            return {
                ...obj[key]
            }
        })
        this.setState({
            orgs
        })
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const { selectedOrg, selectedUser } = this.state
        let changedData = {
            ...selectedUser,
            organisation: selectedOrg.id
        }
        // add user
        const { usersRef } = this.state;
        usersRef
            .child(selectedUser.key)
            .update(changedData)
            .then((value) => {
                console.log("successs", value);
            })
            .catch((err) => {
                console.error("erorr", err);
            })
        // hide modal
        this.props.setModalState(false);
    }

    setSelectedUser = (data) => {
        const { users } = this.state;
        let selectedUser =  users.find((user) => 
            user.key === data.value
        )
        this.setState({
            selectedUser
        });
    }

    setSelectedGroup = (data) => {
        const { orgs } = this.state;
        let selectedOrg =  orgs.find((org) => 
            org.id === data.value
        )
        this.setState({
            selectedOrg
        });
    }

    render() {
        const {isOpen, setModalState} = this.props;
        let usersOptions = this.state.users.map((user) => {
            return {
                key: user.key,
                text: user.name + "/" + user.email,
                value: user.key,
            }
        })
        let orgOptions = this.state.orgs.map((org) => {
            return {
                key: org.id,
                text: org.name,
                value: org.id,
            }
        });
        return (
            <Modal basic open={isOpen} onClose={()=>setModalState(false)}>
                <div 
                style={{
                    textAlign: "-webkit-center"
                }}>
                </div>
                <div style={{textAlign: "center", fontSize: "2vw"}}>
                    <Modal.Header>Add User to Group</Modal.Header>
                </div>
                <Modal.Content>
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Group>
                            <Form.Field width={"12"}>
                                <label style={{color: "white", fontSize: "1.2rem"}}>
                                    User's Name or Email
                                </label>
                                <Dropdown 
                                    placeholder="User's Name / Email" 
                                    search 
                                    selection 
                                    options={usersOptions}
                                    onChange={(e, data) =>this.setSelectedUser(data)}
                                />
                            </Form.Field>
                            <Form.Field width={"16"}>
                                <label style={{color: "white", fontSize: "1.2rem"}}>
                                    Name of Group
                                </label>
                                <Dropdown 
                                    placeholder="Name of Group" 
                                    search 
                                    selection 
                                    options={orgOptions}  
                                    onChange={(e, data) =>this.setSelectedGroup(data)} 
                                />
                            </Form.Field>
                        </Form.Group>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button color="green" inverted 
                        onClick={this.handleSubmit}
                    >
                        <Icon name="save" /> Add
                    </Button>
                    <Button color="red" inverted onClick={()=> setModalState(false)}>
                        <Icon name="remove" /> Cancel
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    }
}


export default AddGroupUserModal