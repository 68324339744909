// user action types
export const SET_USER = 'SET_USER';
export const CLEAR_USER = 'CLEAR_USER';

// channel action types
export const SET_CURRENT_CHANNEL = 'SET_CURRENT_CHANNEL';
export const SET_PRIVATE_CHANNEL = 'SET_PRIVATE_CHANNEL';
export const SET_USER_POSTS = 'SET_USER_POSTS';

// Colors action types
export const SET_COLORS = 'SET_COLORS';

// display setting action types
export const SET_DISPLAY_STATE_OF_METAPANEL = 'SET_DISPLAY_STATE_OF_METAPANEL';
export const SET_DISPLAY_STATE_OF_MESSAGES = 'SET_DISPLAY_STATE_OF_MESSAGES';
export const SET_DISPLAY_STATE_OF_ORGTABLE = 'SET_DISPLAY_STATE_OF_ORGTABLE';

// side bar setting action types
export const SET_ACTIVE_ITEM_OF_ADMIN_SIDEBAR = 'SET_ACTIVE_ITEM_OF_ADMIN_SIDEBAR';

// org edit modal setting action types
export const SET_MODAL_SHOW_STATE = 'SET_MODAL_SHOW_STATE';
// areasOf expertise modal setting action types
export const SET_MODAL_SHOW_AREAS_OF_EXPERTISE = 'SET_MODAL_SHOW_AREAS_OF_EXPERTISE';
export const SET_MODAL_HIDE_AREAS_OF_EXPERTISE = 'SET_MODAL_HIDE_AREAS_OF_EXPERTISE';


// USER eidt modal setting action types
export const SET_EDIT_USER_MODAL = 'SET_EDIT_USER_MODAL';

// Group USER eidt modal setting action types
export const SET_EDIT_GROUP_USER_MODAL = 'SET_EDIT_GROUP_USER_MODAL';

// seach User index setting action types
export const SET_SEARCH_USER = 'SET_SEARCH_USER';
export const SET_SEARCH_TYPE = 'SET_SEARCH_TYPE';

// selected User setting action types
export const SET_SELECTED_USER = 'SET_SELECTED_USER';
