import React, { Component } from 'react';
import { Menu, Label } from 'semantic-ui-react';
import { connect } from 'react-redux';
import firebase from '../../firebase';
import { setCurrentChannel, setPrivateChannel, setUser } from '../../actions';

class MyOrganizationsMessage extends Component {
  state = {
    user: this.props.currentUser,
    channels: [],
    channelName: '',
    channelDetails: '',
    modal: false,
    firstLoad: true,
    // orgshow: false,
    activeChannel: '',
    channel: null,
    messages: {},
    channelsRef: firebase.database().ref('organisations'),
    usersRef: firebase.database().ref('users'),
    messagesRef: firebase.database().ref('messages'),
    typingRef: firebase.database().ref('typing'),
  };

  componentDidMount() {
    this.addListeners();
  }

  componentWillUnmount() {
    this.removeListeners();
  }

  addListeners = () => {
    let loadedOrgs = [];
    const { user } = this.state;
    let userOrgID = user.info.organisation;
    this.state.channelsRef.on('child_added', (snap) => {
      let val = snap.val();
      loadedOrgs.push(val);

      let filteredChannels = [];
      filteredChannels = loadedOrgs.filter((org) => {
        return org.id === userOrgID;
      });
      if (filteredChannels.length - 1 > -1) {
        this.setState({ channels: filteredChannels }, () => {
          if (this.state.firstLoad) {
            this.setState({
              firstLoad: false,
            });
            setTimeout(() => {
              this.changeChannel(this.state.channels[0]);
            }, 500);
          }
        });
      }
    });
    if (this.state.user.info && this.state.user.info.organisation) {
      const organisation = this.state.user.info.organisation;
      if (!organisation) {
        return;
      }
      this.state.messagesRef.child(organisation).on('value', (snap) => {
        let val = snap.val();
        if (val) {
          let ms = Object.keys(val).map((key) => val[key]);
          ms = ms.filter((m) => m.user.id !== this.state.user.uid);

          let messages = this.state.messages;
          if (!this.state.messages[organisation]) {
            // init
            messages[organisation] = {};
            messages[organisation].totalCount = ms.length;
            messages[organisation].newMessagesCount = 0;
          } else {
            messages[organisation].newMessagesCount =
              ms.length - messages[organisation].totalCount;
          }
          this.setState({
            messages,
          });
        }
      });
    }
  };

  removeListeners = () => {
    this.state.channelsRef.off();
    this.state.channels.forEach((channel) => {
      this.state.messagesRef.child(channel.id).off();
    });
  };

  changeChannel = (channel) => {
    this.setActiveChannel(channel);
    this.props.setCurrentChannel(channel);
    this.props.setPrivateChannel(false);
    this.setState({ channel });
  };

  setActiveChannel = (channel) => {
    this.setState({ activeChannel: channel.id });
  };

  getNewMessageCount = (channel) => {
    if (!channel || !channel.id) {
      return;
    }
    let chanelData = this.state.messages[channel.id];
    if (chanelData && chanelData.newMessagesCount > 0) {
      return chanelData.newMessagesCount;
    }
  };

  makeReadedMessage = (key) => {
    let messages = Object.assign({}, this.state.messages);
    if (messages[key]) {
      messages[key].totalCount += messages[key].newMessagesCount;
      messages[key].newMessagesCount = 0;
      this.setState({
        messages,
      });
    }
  };

  displayChannels = (channels) =>
    channels.length > 0 &&
    channels.map((channel) => (
      <Menu.Item
        key={channel.id}
        onClick={() => {
          this.changeChannel(channel);
          this.makeReadedMessage(channel.id);
        }}
        name={channel.name}
        style={{ opacity: 0.7 }}
        active={channel.id === this.state.activeChannel}
        disabled={channel.disabled}
      >
        {this.getNewMessageCount(channel) && (
          <Label color="red">{this.getNewMessageCount(channel)}</Label>
        )}
        # {channel.name}
      </Menu.Item>
    ));

  render() {
    const { channels, user } = this.state;
    return (
      <>
        <Menu.Menu className="menu">
          <Menu.Item>
            {/* <span>
              <Icon name="user md" /> ORGANISATION:
            </span>{' '} */}
            {this.getNewMessageCount(
              user.info && user.info.organisationInfo
            ) && (
              <Label color="red">
                {this.getNewMessageCount(
                  user.info && user.info.organisationInfo
                )}
              </Label>
            )}
          </Menu.Item>
          {this.displayChannels(channels)}
        </Menu.Menu>
      </>
    );
  }
}

export default connect(null, {
  setCurrentChannel,
  setPrivateChannel,
  setUser,
})(MyOrganizationsMessage);
