import React, { Component } from 'react';
import {
  Menu,
  Icon,
  Modal,
  Form,
  Input,
  Button,
  Label,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import firebase from '../../firebase';
import { setCurrentChannel, setPrivateChannel, setUser } from '../../actions/';
import axios from 'axios';
import { buildChannelUrlName } from '../../functions';
import { serverUrl } from '../../service/config';

class Channels extends Component {
  state = {
    user: this.props.currentUser,
    channels: [],
    channelName: '',
    channelDetails: '',
    modal: false,
    firstLoad: true,
    activeChannel: this.props.currentChannel
      ? this.props.currentChannel.id
      : '',
    channel: null,
    notifications: [],
    messages: {},
    channelsRef: firebase.database().ref('channels'),
    usersRef: firebase.database().ref('users'),
    messagesRef: firebase.database().ref('messages'),
    typingRef: firebase.database().ref('typing'),
    loading: true,
    childExpertiseHidden: false,
    childPrivateHidden: false,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!Object.is(this.state.activeChannel, nextProps.currentChannel)) {
      if (!this.state.loading) {
        this.setState({
          activeChannel: nextProps.currentChannel
            ? nextProps.currentChannel.id
            : '',
        });
      }
    }
  }

  async componentDidMount() {
    this.addListeners();
    this.setState({
      loading: false,
    });
  }

  componentWillUnmount() {
    this.removeListeners();
  }

  addListeners = () => {
    let loadedChannels = [];
    let userChannels = this.state.user.info.channels;
    this.state.channelsRef.on('child_added', (snap) => {
      let val = snap.val();
      if (userChannels[snap.key] && val.state) {
        val.disabled = userChannels[snap.key].disabled;
        if (userChannels[snap.key].isCustom) {
          val.isCustom = userChannels[snap.key].isCustom;
        }
        loadedChannels.push(val);
        this.setState({ channels: loadedChannels });
        this.newMessagesListener(snap.key);
      }
    });
  };

  newMessagesListener = (channelId) => {
    this.state.messagesRef.child(channelId).on('value', (snap) => {
      let val = snap.val();
      let ms = [];
      let messages = Object.assign({}, this.state.messages);

      if (val === null) {
        if (!messages[channelId]) {
          // init
          messages[channelId] = {};
          messages[channelId].totalCount = 0;
          messages[channelId].newMessagesCount = 0;
        }
      } else {
        ms = Object.keys(val).map((key) => val[key]);
        ms = ms.filter((message) => message.user.id !== this.state.user.uid);
        if (!messages[channelId]) {
          // init
          messages[channelId] = {};
          messages[channelId].totalCount = ms.length;
          messages[channelId].newMessagesCount = 0;
        } else {
          messages[channelId].newMessagesCount =
            ms.length - messages[channelId].totalCount;
        }
      }

      this.setState(
        {
          messages,
        },
        () => {
          if (channelId === this.state.activeChannel) {
            this.makeReadedMessage(channelId);
          }
        }
      );
    });
  };

  makeReadedMessage = (key) => {
    let messages = Object.assign({}, this.state.messages);
    if (messages[key]) {
      messages[key].totalCount += messages[key].newMessagesCount;
      messages[key].newMessagesCount = 0;
      this.setState({
        messages,
      });
    }
  };

  getNewMessageCount = (channel) => {
    let chanelData = this.state.messages[channel.id];
    if (chanelData && chanelData.newMessagesCount > 0) {
      return chanelData.newMessagesCount;
    }
  };

  removeListeners = () => {
    this.state.channelsRef.off();
    this.state.channels.forEach((channel) => {
      this.state.messagesRef.child(channel.id).off();
    });
  };

  addChannel = () => {
    const { channelsRef, channelName, channelDetails, user, usersRef } =
      this.state;
    const key = channelsRef.push().key;
    const newChannel = {
      id: key,
      name: channelName,
      details: channelDetails,
      createdBy: {
        name: user.displayName,
        avatar: user.photoURL,
      },
      state: true,
      logo: 'logo',
    };
    channelsRef
      .child(key)
      .update(newChannel)
      .then(() => {
        this.setState({ channelName: '', channelDetails: '' });
        this.closeModal();
        console.log('channel added');
      })
      .catch((err) => {
        console.error(err);
      });
    usersRef
      .child(`${user.uid}/channels/${key}`)
      .update(newChannel)
      .then(() => console.log('OK'))
      .catch((err) => console.error(err));
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.isFormValid(this.state)) {
      this.addChannel();
    }
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  changeChannel = (channel) => {
    this.setActiveChannel(channel);
    if (this.state.channel) {
      this.state.typingRef
        .child(this.state.channel.id)
        .child(this.state.user.uid)
        .remove();
    }
    this.props.setCurrentChannel(channel);
    this.props.setPrivateChannel(false);
    this.setState({ channel });
  };

  setActiveChannel = (channel) => {
    this.setState({ activeChannel: channel.id });
  };

  displayChannels = (channels) => {
    let first = true;
    return (
      channels.length > 0 &&
      channels.map((channel, index) => {
        return (
          !channel.public && (
            <div key={index}>
              {channel.isCustom && (first = false)}
              <Menu.Item
                key={channel.id}
                onClick={() => {
                  this.changeChannel(channel);
                  this.makeReadedMessage(channel.id);
                }}
                name={channel.name}
                style={{ opacity: 0.7 }}
                active={channel.id === this.state.activeChannel}
                disabled={channel.disabled}
              >
                {this.getNewMessageCount(channel) && (
                  <Label color="red">{this.getNewMessageCount(channel)}</Label>
                )}
                # {channel.name}
              </Menu.Item>
            </div>
          )
        );
      })
    );
  };

  isFormValid = ({ channelName, channelDetails }) =>
    channelName && channelDetails;

  openModal = () => {
    this.setState({ modal: true });
  };

  closeModal = () => {
    this.setState({ modal: false });
  };

  render() {
    let { channels, modal, childExpertiseHidden, childPrivateHidden } =
      this.state;

    const privateChannels = channels.filter((channel) => channel.isCustom);
    const expertiseChannels = channels.filter((channel) => !channel.isCustom);

    return (
      <>
        <Menu.Menu className="menu">
          <Menu.Item
            className="pr-p5"
            onClick={() => {
              this.setState({
                childExpertiseHidden: !this.state.childExpertiseHidden,
              });
            }}
          >
            <span>
              <Icon name="exchange" /> AREAS OF EXPERTISE
            </span>{' '}
            ({expertiseChannels.length}){' '}
            {!childExpertiseHidden ? (
              <Icon name="minus" style={{ cursor: 'pointer' }} />
            ) : (
              <Icon name="plus" style={{ cursor: 'pointer' }} />
            )}
          </Menu.Item>
          {!childExpertiseHidden && this.displayChannels(expertiseChannels)}
        </Menu.Menu>

        {/* private channels */}
        <Menu.Menu>
          <Menu.Item
            className="pr-p5"
            onClick={() => {
              this.setState({
                childPrivateHidden: !this.state.childPrivateHidden,
              });
            }}
          >
            <span>
              <Icon name="users" /> PRIVATE CHANNELS
            </span>{' '}
            ({privateChannels.length}){' '}
            {!childPrivateHidden ? (
              <Icon name="minus" style={{ cursor: 'pointer' }} />
            ) : (
              <Icon name="plus" style={{ cursor: 'pointer' }} />
            )}
          </Menu.Item>
          {!childPrivateHidden && this.displayChannels(privateChannels)}
        </Menu.Menu>

        {/* add channel */}
        <Modal basic open={modal} onClose={this.closeModal}>
          <Modal.Header>Add a Channel</Modal.Header>
          <Modal.Content>
            <Form onSubmit={this.handleSubmit}>
              <Form.Field>
                <Input
                  fluid
                  label="Channel Name"
                  name="channelName"
                  onChange={this.handleChange}
                  style={{ marginBottom: '1em' }}
                />
                <Input
                  fluid
                  label="About the Channel"
                  name="channelDetails"
                  onChange={this.handleChange}
                />
              </Form.Field>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button color="green" inverted onClick={this.handleSubmit}>
              <Icon name="checkmark" /> Add
            </Button>
            <Button color="red" inverted onClick={this.closeModal}>
              <Icon name="remove" /> Cancel
            </Button>
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentChannel: state.channel.currentChannel,
  };
};

const mapDispatchToProps = {
  setCurrentChannel,
  setPrivateChannel,
  setUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(Channels);
