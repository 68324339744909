import React from 'react';
import { connect } from 'react-redux';
import {
  Header,
  Table,
  Button,
  Icon,
  Image,
  Dropdown,
} from 'semantic-ui-react';
import firebase from '../../firebase';
import { setEditGroupUserModal } from '../../actions/index';
import UserService from '../../service/user.service.admin';
import ConfirmModal from '../common/confirmModal';
import AddGroupUserModal from './AddGroupUserModal';

class GroupUserManagementTable extends React.Component {
  state = {};
  constructor(props) {
    super(props);

    this.state = {
      user: this.props.currentUser,
      users: [],
      orgs: [],
      usersRef: firebase.database().ref('users'),
      orgRef: firebase.database().ref('organisations'),
      storageRef: firebase.storage().ref(),
      database: firebase.database(),
      modalOpen: false,
      confirmValue: false,
      selectedGroupUser: null,
      selectedGroup: null,
      filteredUsers: [],
      isOpenAddGroupUserModal: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.getOrgs();
    this.getUsers();
    this.addListeners();
  }

  addListeners() {
    // firebase.database().ref('users').on("child_changed", (snap) => {
    //   console.log("snap.val()", snap.val());
    //   let key = snap.val().key;
    //   console.log(this.state.users);
    // })
  }

  getUsers = () => {
    const { usersRef } = this.state;

    usersRef.on('value', (snap) => {
      this.setUsersToState(snap.val());
    });
  };

  getOrgs = () => {
    const { orgRef } = this.state;
    orgRef.on('value', (snap) => {
      if (snap.val()) {
        this.setOrgsToState(snap.val());
      }
    });
  };

  setUsersToState = (obj) => {
    let users = Object.keys(obj).map((key) => {
      return {
        key,
        ...obj[key],
      };
    });
    this.setState({
      users,
      filteredUsers: users.slice(),
    });
  };

  setOrgsToState = (obj) => {
    let orgs = Object.keys(obj).map((key) => {
      return {
        ...obj[key],
      };
    });
    this.setState({
      orgs,
    });
  };

  deleteUser = (user) => {
    this.setOpen(true);
    this.setState({
      selectedGroupUser: user,
    });
  };

  displayUsers = (users, orgs) => {
    let keys = Object.keys(users);

    return keys.map((key, index) => {
      let org = orgs.find((org) => org.id === users[key].organisation);
      let orgName = org ? org.name : users[key].organisation; // we need to change this code to DEV
      return (
        <Table.Row key={index}>
          <Table.Cell>
            <Header as="h5" textAlign="center">
              {index + 1}
            </Header>
          </Table.Cell>
          <Table.Cell singleLine>{users[key].name}</Table.Cell>
          <Table.Cell singleLine>{users[key].email}</Table.Cell>
          <Table.Cell singleLine>{orgName}</Table.Cell>
          {/* <Table.Cell width={"1"} textAlign={"center"}>
          {
            users[key].state === true ?  <Button  color='green' className="circle" icon>
            </Button>
            :  <Button  color='red' className="circle" icon>
            </Button>
          }
         
        </Table.Cell> */}
          <Table.Cell textAlign={'center'}>
            <Image
              src={users[key].avatar}
              spaced="right"
              className="org-logo"
              size={'mini'}
            />
          </Table.Cell>
          {/* <Table.Cell>
          {users[key].details}
        </Table.Cell> */}
          <Table.Cell width={'1'}>
            <Button.Group>
              <Button
                positive
                onClick={() => {
                  this.editUser(users[key]);
                }}
              >
                Edit
              </Button>
              <Button.Or />
              <Button color="red" onClick={() => this.deleteUser(users[key])}>
                Delete
              </Button>
            </Button.Group>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  editUser = (user) => {
    this.props.setEditGroupUserModal(true, user);
  };

  setOpen = (value) => {
    this.setState({
      modalOpen: value,
    });
  };

  onConfirm = (value) => {
    if (value) {
      let user = this.state.selectedGroupUser;
      if (user.key) {
        UserService.deleteUser(this.state.user, user.key)
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  };

  setSelectedGroup = (data) => {
    const { orgs, users } = this.state;
    let selectedGroup = orgs.find((org) => org.id === data.value);
    let filteredUsers = users.filter(
      (user) => user.organisation === data.value || data.value === ''
    );
    this.setState({
      selectedGroup,
      filteredUsers,
    });
  };

  setStateAddGroupUserModal = (value) => {
    this.setState({
      isOpenAddGroupUserModal: value,
    });
  };

  render() {
    let { orgs, filteredUsers, isOpenAddGroupUserModal } = this.state;
    let stateOptions = [];
    stateOptions.push({
      key: 'all',
      text: 'All',
      value: '',
    });
    orgs.map((org) => {
      return stateOptions.push({
        key: org.id,
        text: org.name,
        value: org.id,
      });
    });

    return (
      <>
        <Header as="h2" icon textAlign="center">
          <Icon name="users" circular />
          <Header.Content>Organisation Users</Header.Content>
        </Header>
        <Dropdown
          placeholder="Groups"
          search
          selection
          options={stateOptions}
          onChange={(e, data) => this.setSelectedGroup(data)}
        />
        <div style={{ float: 'right' }}>
          <Button
            color={'green'}
            onClick={() => this.setStateAddGroupUserModal(true)}
          >
            <Icon name="add" />
            Add User
          </Button>
        </div>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell singleLine>No</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>Organisation / Group</Table.HeaderCell>
              {/* <Table.HeaderCell textAlign={"center"}>State(Enable/Disable)</Table.HeaderCell> */}
              <Table.HeaderCell textAlign={'center'}>Avatar</Table.HeaderCell>
              {/* <Table.HeaderCell>Detail</Table.HeaderCell> */}
              <Table.HeaderCell>Edit</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {filteredUsers.length > 0 && this.displayUsers(filteredUsers, orgs)}
          </Table.Body>
        </Table>
        <ConfirmModal
          open={this.state.modalOpen}
          setOpen={this.setOpen}
          onConfirm={this.onConfirm}
          header={'User Delete'}
          content={'Are you really want to delete this user?'}
        />
        <AddGroupUserModal
          isOpen={isOpenAddGroupUserModal}
          setModalState={this.setStateAddGroupUserModal}
        />
      </>
    );
  }
}

const actions = {
  setEditGroupUserModal,
};
export default connect(null, actions)(GroupUserManagementTable);
