import React, { Component } from 'react';
import {
  Menu,
  Icon,
  Modal,
  Form,
  Input,
  Button,
  Grid,
  Image,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import AvatarEditor from 'react-avatar-editor';
import firebase from '../../firebase';
import { ORGANIZATION } from './SidePanel/type';

import {
  setCurrentChannel,
  setPrivateChannel,
  setActiveItemOfAdminSideBar,
} from '../../actions/';

class Orgenization extends Component {
  state = {
    user: this.props.currentUser,
    orgRef: firebase.database().ref('organisations'),
    croppedImage: '',
    uploadedCroppedImage: '',
    previewImage: '',
    modal: false,
    orgName: '',
    details: '',
    storageRef: firebase.storage().ref(),
    blob: '',
    state: false,
  };

  openModal = () => {
    this.setState({ modal: true });
  };

  displayOrg = () => {
    this.props.setActiveItemOfAdminSideBar(ORGANIZATION);
  };
  handleSubmit = (event) => {
    event.preventDefault();
    if (this.isFormValid(this.state)) {
      this.addOrg();
      this.closeModal();
    }
  };
  addOrg = () => {
    const { orgRef, orgName, details, state, user } = this.state;
    const key = orgRef.push().key;

    const newOrg = {
      id: key,
      name: orgName,
      details: details,
      state: state,
      logo: '',
      createdBy: {
        name: user.displayName,
        avatar: user.photoURL,
      },
    };

    orgRef
      .child(key)
      .update(newOrg)
      .then(() => {
        this.setState({ orgName: '', details: '' });
        this.closeModal();
        this.uploadCroppedImage(key);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  closeModal = () => {
    this.setState({ modal: false });
  };

  handleChange = (event) => {
    if (event.target.name === 'logo') {
      const file = event.target.files[0];
      const reader = new FileReader();

      if (file && file.type.match('image.*')) {
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          this.setState({ previewImage: reader.result });
        };
      }
    } else if (event.target.name === 'state') {
      this.setState({
        state: !this.state.state,
      });
    } else {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
  };

  isFormValid = ({ croppedImage, orgName, details }) =>
    croppedImage && orgName && details;

  uploadCroppedImage = (orgId) => {
    const { storageRef, blob, metadata } = this.state;

    storageRef
      .child(`organisations/` + orgId)
      .put(blob, metadata)
      .then((snap) => {
        snap.ref.getDownloadURL().then((downloadUrl) => {
          this.setState({ uploadedCroppedImage: downloadUrl });
          this.saveLogoUrl(orgId, downloadUrl);
        });
      });
    this.closeModal();
  };

  saveLogoUrl = (key, downloadUrl) => {
    const { orgRef } = this.state;

    orgRef
      .child(key)
      .update({
        logo: downloadUrl,
      })
      .then(() => {
        console.log('save image url success');
      })
      .catch((error) => {
        console.error(error);
      });
  };

  handleCropImage = () => {
    if (this.AvatarEditor) {
      this.AvatarEditor.getImageScaledToCanvas().toBlob((blob) => {
        let imageUrl = URL.createObjectURL(blob);
        this.setState({
          croppedImage: imageUrl,
          blob,
        });
      });
    }
  };

  render() {
    const { modal, previewImage, croppedImage } = this.state;
    const active = this.props.activedSideItemName === ORGANIZATION;
    return (
      <Grid>
        <Menu.Menu className="menu">
          <Menu.Item
            onClick={this.displayOrg}
            style={{ opacity: 0.7 }}
            active={active}
          >
            <span>
              <Icon name="group" /> Organization
            </span>{' '}
            {/* ({channels.length}){' '} */}
            <Icon
              name="add"
              onClick={this.openModal}
              style={{ cursor: 'pointer' }}
            />
          </Menu.Item>
        </Menu.Menu>
        {/* add channel */}
        <Modal basic open={modal} onClose={this.closeModal}>
          <Modal.Header>Add a Organization</Modal.Header>
          <Modal.Content>
            <Form onSubmit={this.handleSubmit}>
              <Form.Field>
                <Input
                  fluid
                  label="Name of Organisation"
                  name="orgName"
                  onChange={this.handleChange}
                  style={{ marginBottom: '1em' }}
                />
              </Form.Field>
              <Form.Field>
                <Input
                  fluid
                  label="Details of Organisation"
                  name="details"
                  onChange={this.handleChange}
                  style={{ marginBottom: '1em' }}
                />
                <Input
                  fluid
                  label="Logo of Organisation"
                  name="logo"
                  onChange={this.handleChange}
                  type="file"
                />
                <div style={{ textAlign: 'right', color: 'white' }}>
                  <label style={{ color: 'white', paddingLeft: '10' }}>
                    {' '}
                    State{' '}
                  </label>
                  <Form.Radio
                    toggle
                    name="state"
                    onChange={(e) => {
                      e.target.name = 'state';
                      this.handleChange(e);
                    }}
                  />
                </div>
                <Grid centered stackable columns={2}>
                  <Grid.Row centered>
                    <Grid.Column className="ui center aligned grid">
                      {previewImage && (
                        <AvatarEditor
                          image={previewImage}
                          ref={(node) => (this.AvatarEditor = node)}
                          // width={120}
                          // height={120}
                          border={50}
                          scale={1.2}
                        />
                      )}
                    </Grid.Column>
                    <Grid.Column>
                      {croppedImage && (
                        <Image
                          style={{ margin: '3.5em auto' }}
                          width={100}
                          height={100}
                          src={croppedImage}
                        />
                      )}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form.Field>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            {/* {croppedImage && (
            )} */}
            <Button color="green" inverted onClick={this.handleSubmit}>
              <Icon name="save" /> Save
            </Button>
            <Button color="green" inverted onClick={this.handleCropImage}>
              <Icon name="checkmark" /> Preview Logo
            </Button>
            <Button color="red" inverted onClick={this.closeModal}>
              <Icon name="remove" /> Cancel
            </Button>
          </Modal.Actions>
        </Modal>
      </Grid>
    );
  }
}

const actions = {
  setCurrentChannel,
  setPrivateChannel,
  setActiveItemOfAdminSideBar,
};

export default connect(null, actions)(Orgenization);
