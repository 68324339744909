import * as actionTypes from '../actions/types';

const initialOrgEditModalState = {
  isOpenModal: false
}
  
const OrganizationEditModalReducer = (state = initialOrgEditModalState, action) => {
  switch (action.type) {
    case actionTypes.SET_MODAL_SHOW_STATE:
      return {
        ...state,
        isOpenModal: action.payload.isOpenModal,
        selectedOrg: action.payload.selectedOrg
      };
    default:
      return state;
  }
};

export default OrganizationEditModalReducer