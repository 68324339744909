import React, { Component } from 'react';
import { Accordion, Image } from 'semantic-ui-react';
import areas from '../../assets/imgs/areas.png';

class AdminPanelGuard extends Component {
  render() {
    const panels = [
      {
        key: 'Admin Panel',
        title: {
          content: 'How to use the Admin Panel',
          icon: 'question',
        },
        content: {
          content: (
            <>
              <span>
                If you want to share your channel with everyone, you need to
                make it public.
              </span>
              <Image src={areas} />
            </>
          ),
        },
      },
    ];

    return (
      <Accordion
        defaultActiveIndex={[0, 1]}
        panels={panels}
        exclusive={false}
        fluid
      />
    );
  }
}

export default AdminPanelGuard;
