let allowedOrgsForAdmin = [
  {
      name: "Users" // test
  },
  {
      name: "Fleet Forum"
  }
]

module.exports = {
    allowedOrgsForAdmin: allowedOrgsForAdmin,
    isAdmin: function (user) {
        if(user.role && user.role === "admin") {
            return true;
        }
        return false;
    },
    signRole: (user) => {
        let org = allowedOrgsForAdmin.find((orgName) => 
          orgName.name === user.info.organisationInfo.name
        );
        if(org) {
          user.role = "admin";
        } else {
          user.role = "user";
        }
        return user;
      }
}